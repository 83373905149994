<template>
  <el-container direction="vertical">
    <div class="title-wrapper" v-if="titleInfo">
      <div class="title">{{ titleInfo.title }}</div>
      <div class="description">{{ titleInfo.description }}</div>
    </div>
    <router-view />
  </el-container>
</template>

<script>
export default {
  name: 'SetupPage',
  computed: {
    titleInfo() {
      if (!this.$route.name) {
        return {}
      }
      const routeName = this.$route.name
      const titlePrefix = Object.keys(this.setupItems).find(k =>
        routeName.startsWith(k)
      )
      return titlePrefix && this.setupItems[titlePrefix]
    }
  },
  data() {
    return {
      setupItems: {
        'setup.user_info': {
          title: this.$t('사용자 정보'),
          description: this.$t('헬스원 헤라핏의 사용자 정보를 안내해드립니다.')
        },
        'setup.usage': {
          title: this.$t('사용안내'),
          description: this.$t(
            '헬스원을 이용해 주신 고객님들에게 감사드립니다. '
          )
        },
        'setup.logo': {
          title: this.$t('로고&색상 변경'),
          description: this.$t('로고를 사용자에 맞게 관리하실 수 있습니다.')
        },
        'setup.app_info': {
          title: this.$t('앱 안내'),
          description: this.$t('앱을 소개합니다.')
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/_variables.scss';
@import '~@/assets/scss/_mixins.scss';

@include title-wrapper;

.el-container {
  height: 100%;
  /deep/ .setup-contents {
    margin-left: 35px;
    max-width: 1010px;
    margin-bottom: 20px;
    padding: 20px 30px;
    border-radius: 20px;
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
  }

  /deep/ .el-aside {
    border-right: solid 1px $--border-color-base;
    display: flex;
    flex-direction: column;

    .list {
      flex-grow: 1;
      padding: 0;
      margin-bottom: 4px;

      list-style: none;

      li {
        border-bottom: 1px solid $--border-color-base;
        a {
          display: block;
          padding: 20px;
          font-size: 16px;
          color: $--color-text-primary;
          &.router-link-exact-active {
            color: $--color-primary;
          }
        }
      }
    }
  }
  .el-main {
    background-color: white;
  }
}
</style>
