<template>
  <div class="content-step-1">
    <div class="title">
      <span> {{ $t('REGIST_AGREE_GUIDE') }}</span
      ><span class="guide">{{ $t('REGIST_STEP1_GUIDE') }}</span>
    </div>
    <div class="terms-form">
      <TermsKr />
    </div>
    <div class="">
      <el-checkbox v-model="agreed">
        <span class="bold">{{ $t('REGIST_I_AGREE') }}</span
        ><span>{{ $t('REGIST_I_WILL_AGREE') }}</span>
      </el-checkbox>
    </div>
    <div class="actions">
      <el-button type="info" @click="cancel">
        {{ $t('CANCEL') }}
      </el-button>
      <el-button type="primary" @click="next">
        {{ $t('CONFIRM') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import TermsKr from '@/views/pages/terms/TermsKr'
export default {
  name: 'Step1',
  components: { TermsKr },
  data() {
    return {
      agreed: false
    }
  },
  methods: {
    cancel() {
      this.$router.back()
    },
    next() {
      if (!this.agreed) {
        this.$alert(this.$t('REGIST_MUST_AGREE'), null, {
          confirmButtonText: this.$t('CONFIRM'),
          showClose: false
        })
      } else {
        this.$emit('next', this.agreed)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/_variables.scss';
@import '~@/assets/scss/_mixins.scss';

.title {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: $dark-black;
  margin-bottom: 10px;
  .guide {
    &:before {
      content: '*';
    }
    color: $--color-primary;
  }
}
.content-step-1 {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .terms-form {
    flex: 1 0;
    padding: 20px;
    overflow-y: auto;
    border: solid 1px $border-grey;
    margin-bottom: 12px;
  }
}

.el-checkbox {
  /deep/ {
    @include custom-check;
  }
  span {
    color: $text-grey;
    &.bold {
      color: $dark-black;
    }
  }
}

.actions {
  @include actions;
}
</style>
