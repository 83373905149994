/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const vm = new Vue()

export default new Vuex.Store({
  state: {
    layout: {
      type: 'dashboard',
      viewAnimation: 'fade-top' // fade-left, fade-right, fade-top, fade-top-in-out, fade-bottom, fade-bottom-in-out, fade, false
    },
    user: null,
    logged: false,
    resources: {}
  },
  mutations: {
    setLayout(state, payload) {
      state.layout = payload
    },
    setLogin(state, payload) {
      state.logged = true
      state.user = payload
    },
    setLogout(state, payload) {
      state.logged = false
      state.user = null
    },
    setSplashScreen(state, payload) {
      state.splashScreen = payload
    },
    setResource(state, {resourceName, data}) {
      state.resources = Object.assign({}, state.resources, {[resourceName]: data})
    }
  },
  actions: {
    loadResource(context, resourceName) {
      vm.$apiServer.get(`api/v1/${resourceName}.json`).then(response => {
        context.commit('setResource', {resourceName, data: response.data})
      })
    }
  },
  getters: {
    isLoggedIn(state) {
      return state.logged
    },
    user(state, getters) {
      return state.user
    },
    layout(state, getters) {
      return state.layout
    },
    resources(state) {
      return state.resources
    }
  },
  // plugins: [createPersistedState({ paths: ['layout'] })],
  strict: debug
})
