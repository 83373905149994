<template>
  <div>
    <span v-if="disabled">{{ option[value] }}</span>
    <el-radio-group
      v-else
      :value="value && value.toString()"
      @input="data => $emit('input', data)"
    >
      <el-radio v-for="(v, k) in option" :key="k" :label="k">{{ v }}</el-radio>
    </el-radio-group>
  </div>
</template>

<script>
export default {
  name: 'SFRadioSelect',
  props: ['value', 'option', 'disabled']
}
</script>

<style scoped lang="scss">
.el-input.is-disabled {
  /deep/ .el-input__inner {
    background-color: initial;
    border: none;
    color: inherit;
    cursor: initial;
  }
}
</style>
