var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"signup-wrapper"},[_vm._m(0),_c('el-steps',{attrs:{"active":_vm.step,"align-center":""}},[_c('el-step',{attrs:{"title":_vm.$t('REGIST_STEP1_TEXT')}}),_c('el-step',{attrs:{"title":_vm.$t('REGIST_STEP3_TEXT')}}),_c('el-step',{attrs:{"title":_vm.$t('REGIST_STEP2_TEXT')}})],1),_c('section',{staticClass:"content container-fluid"},[_c('div',{staticClass:"mga15 text-center ftsm",class:{ 'text-orange': _vm.step < 4 }},[(_vm.step == 4)?[_c('span',{staticClass:"text-orange ftsm"},[_vm._v(_vm._s(_vm.$t('REGIST_FINISH_GUIDE')))]),_c('h3',[_vm._v(_vm._s(_vm.$t('REGIST_FINSIH_TEXT')))])]:_vm._e()],2),(_vm.step == 1)?_c('Step1',{on:{"next":data => {
          _vm.shop = { terms_agreement: data }
          _vm.step = 2
        }}}):_vm._e(),(_vm.step == 2)?_c('Step3',{on:{"next":data => {
          _vm.shop = Object.assign(_vm.shop, data)
          _vm.step = 3
        }}}):_vm._e(),(_vm.step == 3)?_c('Step2',{attrs:{"shop":_vm.shop},on:{"next":_vm.onRegistered}}):_vm._e(),(_vm.step == 4)?_c('Final'):_vm._e()],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo-wrapper"},[_c('img',{staticClass:"logo",attrs:{"height":"52px","src":require("@/assets/img/logo.png")}})])
}]

export { render, staticRenderFns }