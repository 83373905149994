<template>
  <ResourcePage :query="query" :search-keyword="search" :search-keys="['name']">
    <template #title>
      {{ $t('MENU_CLASS') }}
    </template>
    <template #description>
      {{ $t('클래스를 관리하실 수 있습니다.') }}
    </template>
    <template #filter-old>
      <el-form label-position="top">
        <el-row :gutter="4">
          <el-col :span="12">
            <el-form-item :label="$t('SHOW_DEVICE_TYPE')">
              <el-select v-model="query.class_type">
                <el-option :value="null" :label="$t('전체')"></el-option>
                <el-option
                  class="with-icon"
                  v-for="i in resources.class_types"
                  :value="i.id"
                  :label="$t(i.name)"
                  :key="i.id"
                >
                  <i class="img-icon small" :class="i.icon" />
                  <span>{{ $t(i.name) }}</span></el-option
                >
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('SHOW_BY_TYPE')">
              <el-select v-model="query.workout_type">
                <el-option :value="null" :label="$t('전체')"></el-option>
                <el-option
                  v-for="i in resources.workout_types"
                  :value="i.id"
                  :label="$t(i.name)"
                  :key="i.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item>
            <el-input
              :placeholder="$t('NAME_SEARCHING')"
              suffix-icon="el-icon-search"
              v-model="search"
            >
            </el-input>
          </el-form-item>
        </el-row>
      </el-form>
    </template>
    <template #list-item="scope">
      <div class="list-item">
        <span v-html="toHighlight(search, scope.row.name)" />
      </div>
    </template>
    <template #detail>
      <ClassForm />
    </template>
  </ResourcePage>
</template>

<script>
import ClassForm from '@/views/pages/ClassForm'
import ResourcePage from '@/components/ResourcePage'
import MixinFormatUtils from '@/lib/MixinFormatUtils'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'ClassPage',
  mixins: [MixinFormatUtils],
  components: { ResourcePage, ClassForm },
  props: ['id', 'schema'],
  data() {
    return {
      workoutTypes: null,
      classTypes: null,
      trainers: null,
      members: null,
      devices: null,
      query: {
        class_type: null,
        workout_type: null
      },
      search: ''
    }
  },
  computed: {
    ...mapGetters(['resources'])
  },
  methods: {
    ...mapActions(['loadResource']),
    loadOptions() {
      this.loadResource('workout_types')
      this.loadResource('class_types')
    }
  },
  mounted() {
    this.loadOptions()
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/_variables.scss';

.el-select-dropdown__item.with-icon {
  > * {
    vertical-align: middle;
  }
}

.img-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  &.small {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
}
</style>
