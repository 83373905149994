<template>
  <div class="history-content" v-if="formType === 'history'">
    <Report v-loading="!historyDetail" :detail="historyDetail"> </Report>
  </div>
  <ResourceForm v-else :schema="schema" :id="id" @loadData="onFormLoad">
    <template #header="scope">
      <h2 v-if="formType === 'show'">
        {{ scope.form.name
        }}<span class="title-sub">{{ $t('님의 운동정보') }}</span>
      </h2>
      <h2 v-else>
        {{ scope.form.name }}
      </h2>
    </template>
    <h2 slot="empty">{{ $t('회원을 선택해주세요.') }}</h2>
    <h2 slot="new">{{ $t('새 회원 추가') }}</h2>

    <template #show="scope">
      <div class="member-show">
        <h3>{{ $t('개인정보') }}</h3>
        <div class="member-info">
          <div class="item">
            <span class="label">{{ $t('이름 (성별)') }}</span>
            <span class="value">{{
              `${scope.form.name} (${
                scope.form.gender === 0 ? $t('MALE') : $t('FEMALE')
              })`
            }}</span>
          </div>
          <div class="item">
            <span class="label">{{ $t('신장') }}</span>
            <span class="value">{{ scope.form.height + ' cm' }}</span>
          </div>
          <div class="item">
            <span class="label">{{ $t('생년월일') }}</span>
            <span class="value">{{ scope.form.birth_day }}</span>
          </div>
          <div class="item">
            <span class="label">{{ $t('체중') }}</span>
            <span class="value">{{ scope.form.weight + ' kg' }}</span>
          </div>
          <div class="item">
            <span class="label">{{ $t('핸드폰') }}</span>
            <span class="value">{{ scope.form.phone }}</span>
          </div>
          <div class="item">
            <span class="label">{{ $t('안정심박') }}</span>
            <span class="value">{{ scope.form.optimal_hr + ' bpm' }}</span>
          </div>
          <div class="item">
            <span class="label">{{ $t('이메일') }}</span>
            <span class="value">{{ scope.form.email }}</span>
          </div>
          <div class="item">
            <span class="label">{{ $t('최대심박') }}</span>
            <span class="value">{{
              (scope.form.max_hr || getMaxHr(scope.form.birth_day)) + ' bpm'
            }}</span>
          </div>
        </div>
        <h3>{{ $t('운동이력') }}</h3>
        <div class="history">
          <div
            class="history-item"
            v-for="(h, i) in history"
            :key="i"
            @click="onClickHistoryItem(h.workout_class_execution)"
          >
            <div>{{ toStartedTime(h.started_at) }}</div>
            <div>{{ toRunningTime_3(h.running_time) }}</div>
            <div class="center">{{ h.class_name }}</div>
            <div class="right">{{ Math.round((h.calories/3)+6) + 'kcal' }}</div>
            <div class="right">{{ Math.round(h.avg_hr) + 'bpm' }}</div>
            <div class="right">{{ h.max_hr + 'pbm' }}</div>
          </div>
        </div>
      </div>
    </template>
  </ResourceForm>
</template>

<script>
import ResourceForm from '@/components/ResourceForm'
import MixinFormatUtils from '@/lib/MixinFormatUtils'
import Report from '@/components/Report'
export default {
  name: 'MemberForm',
  mixins: [MixinFormatUtils],
  props: ['classes'],
  components: { Report, ResourceForm },
  computed: {
    id() {
      return this.$route.params.id
    },
    formType() {
      return this.$route.meta && this.$route.meta.formType
    },
    wceId() {
      return this.$route.params.wce_id
    }
  },
  watch: {
    wceId() {
      if (this.wceId) {
        this.loadHistoryDetail()
      }
    }
  },
  data() {
    const schema = {
      name: {
        label: this.$t('NAME'),
        type: 'string',
        option: {
          disabled: true
        }
      },
      gender: {
        label: this.$t('GENDER'),
        type: 'radio',
        resource: 'workout_types',
        option: {
          0: this.$t('MALE'),
          1: this.$t('FEMALE')
        }
      },
      height: {
        label: this.$t('HEIGHT'),
        type: 'integer',
        option: {
          unit: 'cm'
        }
      },
      weight: {
        label: this.$t('WEIGHT'),
        type: 'integer',
        option: {
          unit: 'kg'
        }
      },
      birth_day: {
        label: this.$t('BIRTHDAY'),
        type: 'datetime'
      },
      optimal_hr: {
        label: this.$t('안정 심박수'),
        type: 'integer',
        option: {
          unit: 'bpm'
        }
      },
      max_hr: {
        label: this.$t('최대 심박수'),
        type: 'integer',
        option: {
          unit: 'bpm'
        }
      },
      email: {
        label: this.$t('EMAIL'),
        type: 'string',
        option: {
          disabled: true
        }
      },
      phone: {
        label: this.$t('휴대폰'),
        type: 'string',
        option: {
          disabled: true
        }
      }
    }
    const show = Object.assign({}, schema, {
      created_at: {
        label: this.$t('REGIST_DATE'),
        type: 'datetime'
      },
      classes: {
        label: this.$t('MATCHING_CLASS'),
        type: 'multi_select',
        option: {
          resource: 'classes'
        }
      }
    })
    return {
      historyDetail: null,
      history: [],
      schema: {
        edit: schema,
        show: show
      }
    }
  },
  methods: {
    onFormLoad() {
      this.history = []
      this.$apiServer
        .get(`api/v1/members/${this.id}/history.json`)
        .then(response => {
          this.history = response.data
        })
        .catch(() => {
          this.$router.replace({ name: this.$route.meta.pathName })
        })
    },
    onClickHistoryItem(wceId) {
      this.$router.push({
        name: this.$route.meta.pathName + '.history.show',
        params: { wce_id: wceId, id: this.id }
      })
    },
    loadHistoryDetail() {
      this.historyDetail = null
      this.$apiServer
        .get(`api/v1/history/${this.wceId}/member/${this.id}/hr.json`)
        .then(response => {
          this.historyDetail = response.data
        })
        .catch(() => {
          this.$router.replace({ name: this.$route.meta.pathName })
        })
    }
  },
  mounted() {
    if (this.formType === 'history' && this.wceId) {
      this.loadHistoryDetail()
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/_variables.scss';
@import '~@/assets/scss/_mixins.scss';
$max-width: 800px;

.el-form {
  max-width: $max-width;
  border: 1px solid $--border-color-base;
  padding: 20px;
  border-radius: 4px;
  .el-input {
  }
}

.title-sub {
  font-size: 20px;
}

h3 {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px;
}

.member-info {
  padding: 20px 12px;
  margin-left: -10px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: solid 1px $light-grey;
  width: calc(100% + 10px);
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 50%;
    display: flex;
    font-size: 16px;
    > .label {
      width: 100px;
      color: $text-grey;
    }
    > .value {
      flex-grow: 1;
    }
    margin-bottom: 18px;
  }
}

@include history-list;

.history-content {
  flex: 1;
}
</style>
