<template>
  <div class="page-center-wrap">
    <div class="login-content-wrapper">
      <div class="logo">
        <img src="~@/assets/img/logo.png" />
      </div>
      <div class="form">
        <div class="input-group">
          <span class="icon email"></span>
          <input
            @keydown.enter="login"
            name="email"
            type="email"
            v-model="loginData.email"
            class="login-text-box"
            :placeholder="$t('LOGIN_EMAIL')"
          />
        </div>
        <div class="input-group">
          <span class="icon pw"></span>
          <input
            @keydown.enter="login"
            name="password"
            type="password"
            v-model="loginData.password"
            class="login-text-box"
            :placeholder="$t('LOGIN_PW')"
          />
        </div>
        <div>
          <button class="btn-login" @click="login">
            {{ $t('LOGIN') }}
          </button>
          <p v-if="error" class="error">{{ $t('NO_MANAGER_INFO') }}</p>
        </div>

        <div class="login-option">
          <span class="pull-left">
            <SFCheckBox class="login">{{ $t('LOGIN_SAVEID') }}</SFCheckBox>
          </span>
          <span class="pull-right">
            <!-- <a class="ftsm font-gray">{{ $t('LOGIN_FINDINFO') }}</a> -->
            <router-link
              :to="{ name: 'Signup' }"
              class="text-underbar font-gray ftsm"
              >{{ $t('LOGIN_REGIST') }}</router-link
            >
          </span>
        </div>
      </div>
      <div class="login-box-footer text-center"></div>
    </div>
    <!-- /.social-auth-links -->
    <!-- /.login-box-body -->
  </div>
</template>

<script>
import SFCheckBox from '@/components/form/SFCheckBox'
export default {
  name: 'LoginPage',
  components: { SFCheckBox },
  computed: {
    redirect() {
      return this.$route.query.redirect
    },
    saveShop() {
      return this.$route.query.save_shop
    }
  },
  data() {
    return {
      loginData: {
        email: null,
        password: null
      },
      error: null
    }
  },

  methods: {
    gotoRedirect() {
      this.$router.push(this.redirect ? this.redirect : '/')
    },
    login() {
      this.error = null
      this.$apiServer
        .post('auth/token/login', this.loginData)
        .then(response => {
         
          window.sessionStorage.setItem('auth_token', response.data.auth_token)
          if (this.saveShop) {
            this.$apiServer.get('auth/users/me.json').then(response => {
              window.localStorage.setItem('shop_id', response.data.shop_id)
              this.gotoRedirect()
            })
          } else {
            this.gotoRedirect()
          }
        })
        .catch(error => {
          console.log("error:"+error)
          if (error.response.status == 400) {
            this.error = error.response.data
          }
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/_variables.scss';
@import '~@/assets/scss/_mixins.scss';

p.error {
  font-size: 14px;
  line-height: 20px;
  margin: -20px 0 0;
  text-align: center;
  color: $--color-danger;
}

.page-center-wrap {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.login-content-wrapper {
  width: 340px;
}

.logo {
  img {
    width: 100%;
  }
  margin-bottom: 50px;
}

.form {
  padding: 0 5px;
}

.input-group {
  box-sizing: border-box;
  height: 60px;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid $border-grey;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  input {
    font-size: 16px;
    padding: 15px 15px 15px 20px;
    background-color: transparent;
    border: none;
    flex: 1;
    &:focus {
      outline: none;
    }
    color: $text-grey;
  }
  .icon {
    background-color: $dark-black;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 59px;
    border-right: 1px solid $border-grey;
    &:before {
      content: '';
      display: inline-block;
      width: 25px;
      height: 25px;
      background-size: 100%;
    }
    &.email:before {
      background-image: url('~@/assets/icons/user.png');
    }
    &.pw:before {
      background-image: url('~@/assets/icons/lock.png');
    }
  }
}

.btn-login {
  cursor: pointer;
  background-color: $dark-black;
  border: none;
  color: $text-grey;
  width: 100%;
  height: 60px;
  border-radius: 10px;
  font-size: 20px;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.16);
  margin-bottom: 20px;
}

.el-checkbox.login {
  /deep/ {
    @include custom-check;
  }
}

.login-option {
  display: flex;
  color: $text-grey2;
  font-size: 13px;
  justify-content: space-between;
  .pull-right {
    align-self: stretch;
  }
  /deep/ {
    a {
      &:last-child {
        margin-right: 0;
      }
      line-height: 19px;
      margin-right: 20px;
      text-decoration: none;
      &:visited,
      &:active,
      &:-webkit-any-link {
        color: $text-grey2;
      }
    }
  }
}
</style>
