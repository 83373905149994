<template>
  <div class="content-wrapper main">
    <div class="title-wrapper">
      <div class="title">{{ $t('LOGGED_IN') }}</div>
      <div class="description">
        <div>{{ $t('LABEL_USER') }} : {{ user.name }}</div>
        <div>{{ $t('LABEL_ID') }} : {{ user.email }}</div>
      </div>
    </div>
    <section class="cards">
      <div class="card" @click="$router.push({name: 'herafit'})">
        <div class="icon-wrap">
          <img src="~@/assets/icons/dashboard-herafit.png" />
        </div>
        <div class="label">{{ $t('DASHBOARD_TOTAL_HERAFIT') }}</div>
        <div class="count">{{ summary.device_cnt }}</div>
      </div>

      <div class="card" @click="$router.push({name: 'member'})">
        <div class="icon-wrap">
          <img src="~@/assets/icons/dashboard-member.png" />
        </div>
        <div class="label">{{ $t('DASHBOARD_TOTAL_MEMBER') }}</div>
        <div class="count">{{ summary.member_cnt }}</div>
      </div>

      <div class="card" @click="$router.push({name: 'class'})">
        <div class="icon-wrap">
          <img src="~@/assets/icons/dashboard-class.png" />
        </div>
        <div class="label">{{ $t('DASHBOARD_TOTAL_CLASS') }}</div>
        <div class="count">
          {{
            Object.values(summary.class_cnt).reduce((sum, cnt) => sum + cnt, 0)
          }}
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'DashBoard',
  computed: {
    ...mapGetters(['user']),
    workoutTimeRange() {
      const data = this.summary && this.summary.last_execution
      if (!data) {
        return null
      }
      return (
        moment(data.started_at.started_at).format('YYYY-MM-DD HH:mm') +
        ' - ' +
        moment(data.stopped_at).format('HH:mm')
      )
    }
  },
  data() {
    return {
      icons: {
        treadmill: null,
        walkro: null,
        cycle: null,
        weight: null
      },
      refreshInterval: null,
      summary: {
        class_cnt: null,
        last_execution: {}
      },
      classStatus: [
        { type_icon: 'treadmill', type_name: 'THREAD_MIL', class_type: 1 },
        { type_icon: 'walkro', type_name: 'WORKROW', class_type: 2 },
        { type_icon: 'cycle', type_name: 'CYCLE', class_type: 3 },
        { type_icon: 'weight', type_name: 'WEIGHT_TRAINING', class_type: 4 }
      ]
    }
  },
  methods: {
    loadSummary() {
      this.$apiServer.get('api/v1/dashboard_summary.json').then(response => {
        this.summary = response.data
      })
    },
  },
  mounted() {
    this.loadSummary()
    this.refreshInterval = setInterval(() => this.loadSummary(), 30000)
  },
  destroyed() {
    clearInterval(this.refreshInterval)
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/_variables.scss';

.title-wrapper {
  margin-bottom: 45px;
  .title {
    line-height: 37px;
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 20px;
  }
  .description {
    line-height: 24px;
    font-weight: 300;
    font-size: 16px;
  }
}
.cards {
  display: flex;
  .card {
    cursor: pointer;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    width: 260px;
    min-width: 230px;
    margin-right: 20px;
    text-align: center;
    .icon-wrap {
      margin-bottom: 15px;
      padding: 30px 0;
      height: 120px;
      img {
        height: 120px;
        width: auto;
      }
    }
    .label {
      font-size: 16px;
      color: $text-grey2;
      margin-bottom: 15px;
    }
    .count {
      font-size: 50px;
      font-weight: 300;
      font-family: GmarketSans, 'Noto Sans Korean', sans-serif;
      color: $--color-primary;
    }
  }
}
.main.content-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 65px 50px;
}

img.icon-all {
  height: 120px;
  margin-bottom: 10px;
}

.regist-class-status {
  .item {
    .class_img {
      text-align: center;
      line-height: 95px;
      height: 95px;
      img {
        display: inline;
        height: 80px;
        width: auto;
        margin: 0 auto;
        vertical-align: middle;
      }
    }
    .class_title {
      margin-top: 15px;
    }
    h3 {
      margin-top: 20px;
    }
  }
}
</style>
