<template>
  <el-input :value="value" @input="(data) => $emit('input', data)" type="password"></el-input>
</template>

<script>
export default {
  name: 'SFInputPassword',
  props: ['value']
}
</script>

<style scoped></style>
