<template>
  <div>
    <span>{{ (value && value.length) || 0 }}</span
    >&nbsp;명&nbsp;<el-button
      size="small"
      v-if="!disabled"
      @click="dialogVisible = true"
      >편집</el-button
    >
    <el-dialog
      :visible.sync="dialogVisible"
      append-to-body
      :title="$t('회원 매칭')"
    >
      <div class="list-wrap">
        <table>
          <thead>
            <th>{{ $t('회원') }}</th>
            <th>{{ $t('헤라핏') }}</th>
            <th></th>
          </thead>
          <tbody>
            <tr v-for="(d, i) in data" :key="i">
              <td>
                <SFResourceSelect
                  size="small"
                  filterable
                  :placeholder="$t('CHOOSE_MEMBER')"
                  :option="members(d.member)"
                  v-model="d.member"
                />
              </td>
              <td>
                <SFResourceSelect
                  size="small"
                  filterable
                  :placeholder="$t('CHOOSE_HERAFIT')"
                  :option="device_infos(d.device)"
                  v-model="d.device"
                />
              </td>
              <td>
                <el-button size="small" @click="data.splice(i, 1)">{{
                  $t('REMOVE')
                }}</el-button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="list-item">
          <el-button size="mini" @click="onAdd">{{ $t('ADD') }}</el-button>
        </div>
      </div>
      <div slot="footer" class="actions">
        <el-button size="small" @click="dialogVisible = false">{{
          $t('CANCEL')
        }}</el-button>
        <el-button size="small" type="primary" @click="onConfirm">{{
          $t('CONFIRM')
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SFResourceSelect from '@/components/form/SFResourceSelect'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'SFMatchingMember',
  components: { SFResourceSelect },
  props: ['value', 'disabled'],
  computed: {
    ...mapGetters(['resources']),
  },
  watch: {
    resources() {
      this.$forceUpdate()
    },
    value() {
      this.data = JSON.parse(JSON.stringify(this.value)) || []
      this.data.push({})
    }
  },
  data() {
    return {
      data: [],
      dialogVisible: false
    }
  },
  methods: {
    ...mapActions(['loadResource']),
    onAdd() {
      this.data.push({})
    },
    members(id) {
      return this.resources.members.filter(
              v => !this.data.map(i => i.member).includes(v.id) || v.id == id
      )
    },
    device_infos(id) {
      return this.resources.device_infos.filter(
              v => !this.data.map(i => i.device).includes(v.id) || v.id == id
      )
    },
    onConfirm() {
      this.dialogVisible = false
      let members = this.data.filter(i => i.member && i.device)
      this.$emit('input', members)
    }
  },
  mounted() {
    this.loadResource('members')
    this.loadResource('device_infos')
    this.data = JSON.parse(JSON.stringify(this.value)) || []
    this.data.push({})
  }
}
</script>

<style scoped lang="scss">
.list-wrap {
  height: 400px;
  .list-item {
    padding: 4px;
  }
  table {
    width: 100%;
    td,
    th {
      padding: 4px;
      /deep/ .el-select {
        width: 100%;
      }
    }
    th {
      padding-left: 8px;
    }
  }
}

.actions {
  text-align: right;
  margin-top: 30px;
}
</style>
