<template>
    <el-checkbox>
        <slot></slot>
    </el-checkbox>
</template>

<script>
  export default {
    name: "SFCheckBox"
  }
</script>

<style scoped>

</style>
