export default {
  "MENU_HOME": "首页",
  "MENU_HERAFIT": "HERA Fit+管理",
  "MENU_TRANINER": "教练员管理",
  "MENU_MEMBER": "会员管理",
  "MENU_CLASS": "课程管理",
  "MENU_HISTORY": "History",
  "MENU_SETUP": "设置",
  "MENU_SETUP_USER": "사용자 정보",
  "MENU_SETUP_RECEIVER": "리시버 설정",
  "MENU_SETUP_USAGE": "사용 안내",
  "MENU_SETUP_LOGO": "로고 변경",
  "MENU_SETUP_APP": "앱 정보",
  "TITLE_DASHBOARD": "HERA Fit ON",
  "TITLE_SUBTITLE": "HERA Fit ON，进行极其有效的健身",
  "TITLE_CLASSMANAGE": "课程管理",
  "DASHBOARD_REGIST_CLASS": "注册课程列表",
  "DASHBOARD_RECENT_CLASS": "最近完成的课程",
  "DASHBOARD_AVG_CAL_BURN": "消耗卡路里的平均值",
  "DASHBOARD_AVG_HEART_RATE": "心率平均值",
  "DASHBOARD_TOTAL_MEMBER": "全部会员",
  "DASHBOARD_TOTAL_TRANINER": "全部教练员",
  "DASHBOARD_TOTAL_HERAFIT": "整个HERA Fit+",
  "LOGIN_EMAIL": "电子邮箱ID",
  "LOGIN_PW": "密码",
  "LOGIN_SAVEID": "保存ID",
  "LOGIN_FINDINFO": "搜索会员信息",
  "LOGIN": "登录",
  "LOGIN_REGIST": "注册管理员",
  "FIND_USERINFO": "搜索会员信息",
  "FIND_ID": "搜索ID",
  "FIND_PW": "搜索密码",
  "FIND_ID_GUIDE": "“输入培训师信息，查找ID，联系ceo@ehealth-one.com获取经理信息。",
  "FIND_ID_TYPE": "Type",
  "FIND_ID_CHECK": "ID检查",
  "FIND_PW_GUIDE": "请输入培训师的信息，以便为经理信息找到密码联系ceo@ehealthone.com。",
  "FIND_INPUT_PW_GUIDE": "输入新口令",
  "FIND_ID_INPUT": "输入电子邮箱ID",
  "FIND_NAME_INPUT": "请输入名字",
  "NEW_PASSWORD_REGIST": "新密码注册",
  "TRAINNER": "教练员",
  "ADMIN": "管理员",
  "ID": "ID",
  "EMAIL": "电子邮箱",
  "NAME": "名称",
  "CLUB_PHONE_NUM": "健身房电话号码",
  "CLUB_NAME": "健身房名称",
  "CLUB_LOCATION": "健身房位置",
  "ONLY_INPUT_NUMBER": "仅输入数字",
  "CONFIRM": "OK",
  "CANCEL": "取消",
  "PASSWORD": "密码",
  "PASSWORD_CHECK": "确认密码",
  "PASSWORD_INPUT": "输入密码",
  "PASSWORD_INPUT_CHECK": "重新输入密码",
  "PASSWORD_CONDITION": "6个以上混合英文和数字的字符",
  "DUP_CHECK": "检查是否重复",
  "REGIST_STEP1_GUIDE": "在注册为管理员后使用",
  "REGIST_STEP2_GUIDE": "登记管理员的基本信息",
  "REGIST_STEP3_GUIDE": "同时也登记健身房的信息",
  "REGIST_FINISH_GUIDE": "管理员注册完成",
  "REGIST_FINSIH_TEXT": "在以管理员身份登录后继续以下操作",
  "REGIST_STEP1_TEXT": "条款和条件协议",
  "REGIST_STEP2_TEXT": "输入基本信息",
  "REGIST_STEP3_TEXT": "输入健身房信息",
  "REGIST_AGREE_GUIDE": "同意启动服务的使用权",
  "REGIST_I_AGREE": "使用权协议",
  "REGIST_I_WILL_AGREE": "Agree to Terms and Conditions",
  "REGIST_MUST_AGREE": "先同意条款",
  "REGIST_INFO_INPUT": "登记管理员的基本信息",
  "REGIST_CLUB_INPUT": "同时也登记健身房的信息",
  "REGIST_CLUB_NAME_INPUT": "输入健身房名称",
  "REGIST_LOCATION_INPUT": "输入健身房位置",
  "REGIST_MEM": "会员注册",
  "REGIST_CLASS": "课程注册",
  "REGIST_TRAINNER": "Trainer registration",
  "MATCHING": "匹配",
  "REGIST_START_HEALTH": "开始健身",
  "SHOW_ALL": "查看全部",
  "TOTAL_CLASS": "整个课程",
  "SHOW_DEVICE_TYPE": "按设备查看",
  "THREAD_MIL": "跑步机",
  "WORKROW": "WalkRo",
  "CYCLE": "自行车",
  "CHALLENGE": "도전 몸짱",
  "SHOW_BY_TYPE": "按类型查看",
  "PLS_REGIST_CLASS": "注册课程列表",
  "NAME_SEARCHING": "搜索名称",
  "NO_REGIST_CLASS": "没有课程注册。",
  "CLASS_ADD": "添加课程",
  "AFTER_MEMBER_MATCHING": "注册课程后，将会员与教练员匹配。",
  "REMOVE": "删除",
  "ADD": "添加",
  "CLASS_DEVICE": "课程设备",
  "CLASS_TYPE": "课程类型",
  "CLASS_NAME": "课程名称",
  "TARGET_HEART_BEAT": "目标心率",
  "MATCHING_MEMBER": "待匹配会员",
  "LIMIT_30": "最多30人",
  "RUNNING_TIME": "经过时间",
  "MIN": "分钟",
  "MATCHING_TRAINNER": "待匹配教练员",
  "CHOOSE_MEMBER": "选择会员",
  "CHOOSE_TRAINNER": "选择培训师",
  "INPUT_CLASS_NAME": "输入名称",
  "CHOOSE_DEVICE": "选择设备",
  "CHOOSE_TYPE": "选择类型",
  "CHOOSE_HEART_BEAT": "选择心率",
  "CHOOSE_TIME": "选择时间",
  "INPUT_HEART_BEAT_PER": "输入心率％",
  //"CLASS_LIST": "类链表",
  "MANAGER_TRAINNER": "待匹配教练员",
  "TOTAL_CLASS_MEMBER": "参加总人数",
  "HEALTH_TIME": "锻炼时间",
  "AVG_MAX_HEART_BEAT": "Avg,最大心率",
  "MATCHING_MEMBER_LIST": "成员列表匹配",
  "TOTAL_BURN_CARORIES": "总消耗卡路里",
  "GOOD_HEART_RATE": "最佳心率",
  "MAX_HEART_BEAT": "最大心率 ",
  "HOLDING_TIME": "占用时间",
  "HEART_BEAT_GRAPH": "心率分布曲线",
  "CLASS_MEMBER": "加总人数",
  "PERSON": "人",
  "SEND_FIT_INFO": "发出",
  "START": "开始",
  "YOUR_ID": "ID是",
  "ID_IS": "。",
  "ID_LOGIN_PLZ": "尝试使用相同的ID登录。",
  "ID_AND_NAME_NOT_MATCH": "<span class=\"text-orange\">ID和名</span>称必须一致。",
  "CHECK_AND_INPUT": "检查并重新输入。",
  "HERAFIT_MANAGE": "HERA Fit+管理",
  "PLS_REGIST_HERAFIT": "注册HERA Fit+",
  "NO_REGIST_HERAFIT": "没有注册HERA Fit+。",
  "HERAFIT_ADD": "添加HERA Fit+",
  "HERAFIT_MODIFY": "修改HERA Fit+",
  "AND_MEMBER_MATCHING": "并将其与会员匹配。",
  "TOTAL_HERAFIT": "整个HERA Fit+",
  "SERIAL_NO": "序列号",
  "OWN_TYPE": "拥有类型",
  "HERAFIT_NAME": "HERA Fit+的名称",
  "CLUB_RENTAL": "健身房出租",
  "PERSONAL_OWN": "私人拥有",
  "COMPLETE": "完成",
  "INPUT_COMPLETE": "입력 완료", // 중국어 번역 없음,
  "MODIFY_COMPLETE": "수정 완료", // 중국어 번역 없음,
  "SHOW_CLUB_RENTAL": "查看健身房出租",
  "SHOW_PERSONAL_OWN": "查看私人拥有",
  "CERTI_DATE": "验证日期",
  "CERTI_COMPELETE": "验证完成",
  "MODIFY": "修改",
  "TRAINNER_MANAGE": "教练员管理",
  "MEMBER_MANAGE": "会员管理",
  "CLASS_MATCHING_COMP_SHOW": "查看完整的课程匹配",
  "CLASS_MATCHING_NOTCOMP_SHOW": "查看课程匹配不完整",
  "TOTAL_TRAINER": "全部教练员",
  "PLS_REGIST_TRAINNER": "注册教练员",
  "NO_REGIST_TRAINNER": "没有注册教练员",
  "TRAINNER_ADD": "教练员注册",
  "AND_CLASS_MATCHING": "并将其与HERA Fit+匹配",
  "NAME_EMAIL_SEARCHING": "名称/电子邮箱搜索",
  "REGIST_DATE": "注册日期",
  "GENDER": "性别",
  "HEIGHT": "身高",
  "WEIGHT": "体重",
  "BIRTHDAY": "生日",
  "MATCHING_CLASS": "课程匹配",
  "MALE": "男",
  "FEMALE": "女",
  "PLZ_INPUT_EMAIL": "输入电邮地址",
  "CHOICE": "选择",
  "PLS_REGIST_MEMBER": "注册会员",
  "TOTAL_MEMBER": "全部会员",
  "MEMBER_ADD": "添加成员",
  "MEMBER_MODIFY": "修改会员",
  "NO_REGIST_MEMBER": "没有注册会员",
  "AFTER_HERAFIT_MATCHING": "添加并将其与HERA Fit+匹配",
  "EMAILNOTEXIST": "能够注册",
  "EMAILDUPLICATE": "已经注册。尝试其他电邮地址",
  "IS_NOT_EMAIL_ADDRESS": "电子邮件地址不正确",
  "PASSWORD_POLICY": "密码（6个以上混合英文和数字的字符）",
  "NOT_MATCH_PASSWORD": "密码错误",
  "PLZ_INPUT_CLUB_TELNO": "健身房电话号码",
  //"SAVE_FAIL":저장 실패", // 중국어 번역 없음,
  "LOGIN_NEEDS_JOIN": "在注册为管理员后使用",
  "PASSWORD_FAIL": "密码错误.",
  "NO_MANAGER_INFO": "관리자 정보가 존재 하지 않습니다",  // 중국어 번역 없음
  "PLZ_INPUT_BIRTHDAY": "输入生日",
  "NO_ID_SEARCH": "해당 정보를 찾을수 없습니다.",  // 중국어 번역 없음
  "PASSWORD_CHANGED": "비밀번호가 변경되었습니다.",  // 중국어 번역 없음
  "FAIL": "失败",
  "PLZ_INPUT_HEIGHT": "输入身高",
  "PLZ_INPUT_WEIGHT": "输入体重",
  "ARE_YOU_SURE_TRAINER_REMOVE": "删除选定的教练员？",
  "ARE_YOU_SURE_HERAFIT_REMOVE": "删除选定的HERA Fit+？",
  "REMOVE_COMPLETE": "删除",
  "NO_SEARCH_RESULT": "没有结果",
  "WEIGHT_TRAINING": "力量训练",
  "NO_TOOL": "没有设备",
  "LIGHT_EXERCISE": "轻运动",
  "OXYGEN_EXERCISE": "有氧锻炼",
  "OXYGEN_FREE_EXERCISE": "无氧锻炼",
  "OXYGEN_AND_FREE_EXERCISE": "有氧/无氧复合锻炼",
  "DIET_EXERCISE": "脂肪燃烧锻炼",
  "CALORIE_GOAL_EXERCISE": "卡路里目标锻炼",
  "HEART_RATE_GOAL_EXERCISE": "心率目标锻炼",
  "TIME_GOAL_EXERCISE": "时间目标锻炼",
  "HERA_ZONE": "HERA区",
  "FREE_EXERCISE": "免费培训",
  "PLZ_INPUT_TIME": "输入时间",
  "WARMING_UP": "热身",
  "FAT_BURNING": "脂肪燃烧",
  "IMPROVE_ENDURANCE": "提高心肺耐力",
  "MAX_EXERCISE_EFFECT": "最佳健身结果",
  "MAX_STRANGTH": "最大强度",
  "MANUAL_INPUT": "输入你自己",
  "PLZ_CHOOSE_DEVICE": "选择设备",
  "PLZ_CHOOSE_TYPE": "选择类型",
  "PLZ_CHOOSE_HEART_BEAT": "输入目标心率",
  "PLZ_CHOOSE_TIME": "输入健身时间",
  "PLZ_CHOOSE_MEMBER": "选择会员",
  "PLZ_CHOOSE_TRAINER": "选择教练员",
  "CHECKED_TRAINER": "教练选择",
  "CHECKED_MEMBER": "已选定会员",
  "MAN_COUNT": "人",
  "CHOOSE_TRAINER_GUIDE": "匹配教练员负责课程",
  "HEALTH_RESULT": "健身小结",
  "ARE_YOU_SURE_CLASS_REMOVE": "删除选定的课程？",
  "ARE_YOU_SURE_USER_REMOVE": "您真的想删除这个成员吗?",
  "CLASS_MODIFY": "修改课程",
  "HEART_RATE_GUIDE": "输入身高，体重和生日后自动计算",
  "CHOOSE_MEMBER_GUIDE": "指定待使用的HERA Fit+以及待加入课程的会员",
  "CHOOSE_HERAFIT": "选择HERA Fit +",
  "PLZ_MATCHING_MEMBER_TO_DEVICE": "将会员与HERA Fit+匹配",
  "SETUP": "设置",
  "ADMININFO": "用户信息",
  "HOWTOUSE": "用户指南",
  "LOGO_CHANGE": "标识变更",
  "APP_GUIDE": "应用程序指南",
  "LOGOUT": "退出",
  "ADMIN_MODIFY": "短期资本经营者",
  "LANGUAGE_SETTING": "选择语言",
  "PREV": "上一项",
  /** DEVICE 관련 */
  "DEVICE_SN_CHECK_OK": "能够注册",
  "DEVICE_SN_BLANK_ERROR": "输入序列号",
  "DEVICE_SN_OVERLAP_ERROR": "已经注册。尝试其他序列号。",
  "DEVICE_NAME_CHECK_OK": "能够注册",
  "DEVICE_NAME_BLANK_ERROR": "输入HERA Fit+的名称",
  // 사용 안함
  "DEVICE_NAME_OVERLAP_ERROR": "重复的名称。请重新输入。",
  "DEVICE_DUPLICATE_MODE_ERROR": "无法确认重复搜索的条件.",
  "DEVICE_DUPLICATE_TYPE_ERROR": "无法确认设备类型。",
  /** 2018.07.09 이후 추가분 */

  "CLASS_SETTING": "课程设置",
  "CLASS_SETTING_GUIDE": "添加/修改课程时设置设备和类型",
  "TYPE_MANAGING": "类型管理",
  "EQUIPMENT_MANAGING_BY_EXCERSISE": "按照设备进行健身管理",
  "HERA_ZONE_GUIDE": "如果你保持了超过12分钟的最高心率的84%，这将使你的脂肪持续增加36小时。",
  "PLZ_INPUT_CLASS_TYPE": "输入类型名称",
  "PLZ_INPUT_EQUIPMENT_NAME": "输入设备名称",
  "TRAINNER_MODIFY": "修改教练员",
  "RANKING": "渠道",
  "UPLOAD": "上传",
  "CHOOSE_FILE": "选择文件",
  "LOGO_UPLOAD_GUIDE": "- 如果图像文件的背景不透明，将会出现白色背景。<br>- 最佳图像尺寸为宽度500像素x长度400像素。如果不合适，将会自动更改。",
  "LOGO_GUIDE": "显示时更改显示的标识",
  "CANNOT_USE_IMAGE_FILE": "无法上传。使用低于10MB的jpg，jpeg，png和bmp等格式文件",
  "PLZ_LOGO_FILE_UPLOAD": "上传首选标识",
  "ANY_QUESTION": "有什么需要说的吗？",
  "MENU_HOME_GUIDE": "这是控制面板，您可以通过应用程序检查和管理信息",
  "MENU_HERAFIT_GUIDE": "通过健身房租赁和私人拥有来管理和注册HERA Fit+。<br>注册新的HERA Fit+需要背面的序列号。",
  "MENU_TRAINER_GUIDE": "注册，修改和删除管理整个课程的教练员。<br>教练员的电子邮箱将用作登录时的ID。请仔细注册。",
  "MENU_MEMBER_GUIDE": "注册加入课程的会员。将根据物理数据自动计算出最佳心率。为了能收到电子邮件，请输入准确的数据。",
  "MENU_CLASS_GUIDE": "在会员与教练员匹配后能够开始。 <br>在显示器连接后实时检查会员的健身数据。可以选择任何课程设备和课程类型。<br><div class=\"red\">- 下课后数据将自动累计。</div>",
  "ALREADY_CLASS_EXIST": "正在进行的类已经存在",
  "PAUSE_CLASS_EXIST": "暂停现有类",
  "STOP": "暂停",
  "RESTART": "重新开始",
  "FINISH": "完成",
  "PLZ_CHOOSE_MEMBER_IN_CLASS": "选择待加入课程的会员",
  "ARE_YOU_SURE_EXCERCISE_STOP": "下课？上一次健身被保存为最近的健身记录。",
  "USERINFO": "用户信息",
  "DISPLAY_CONNECT_GUIDE": "显示器连接指南",
  "PERMISSION_GUIDE": "权限指南",
  "HERAFIT_USE_PERMISSION_GUIDE": "它是使用HERA Fit ON所需访问权限的指南",
  "REQUIRE_PERMISSION": "必需的访问权限",
  "WIFI_CONNECT_INFO": "Wi-Fi连接信息",
  "USE_APP_CHECK_NETWORK": "使用应用程序时检查网络信息",
  "BLUETOOTH_CONNECT_INFO": "蓝牙连接信息",
  "BLUETOOTH_GUIDE": "控制收集连接信息或将其传输到附近的蓝牙设备",
  "MEDIA/FILE": "媒体/文件",
  "FILE_PERMISSION_GUIDE": "访问保存的媒体文件后上传",
  "CONNECT_GUIDE": "连接指南",
  "CAN_USE_HERAFIT_CONNECT": "在连接后使用HERA Fit ON。",
  "CHOOSE_AND_FALLOW_GUIDE": "在选择项目后按照指南继续进行。",
  "YOU_CAN_SEE_SETUP_MENU_TOO": "",
  "DISPLAY_SHARE_CONNECT_APP": "连接应用程序和在课堂上提供健身指导信息的大型显示器",
  "DISPLAY_AND_SERVER": "显示器 - 服务器连接指南",
  "HOWTO_SERVER_CONNECT": "它是和服务器的连接。按照以下指南继续进行。",
  "SERVER_TURN_ON": "打开服务器",
  "TV_INPUT_TYPE_SET": "设置电视输入模式",
  "INPUT_OR_SETUPBOX": "外部输入或机顶盒",
  "TV_READY_CONNECT_SUCCESS": "电视连接成功",
  "DISPLAY_APP_CONNECT": "显示器 - 应用程序连接",
  "MIRRORING_CONNECT": "连接屏幕镜像",
  "CONNECT_COMPLETE": "连接完成",
  "MAYBE_LATER": "稍后继续",
  "NEXT": "下一项",
  "DISPLAY_MIRRORING_CONNECT_GUIDE": "显示器 - 屏幕镜像连接指南",
  "IF_HARD_TO_CONNECT_FALLOW_THIS_GUIDE": "继续镜像设置",
  "INPUT_TV_DONGLE": "将加密狗插入电视",
  //"TURN_ON_DONGLE": "将加密狗接通电源",
  "TURN_ON_TV_AND_INPUT_TYPE_CHOOSE": "打开电视后选择HDMI",
  "TV_SCREEN_CHOOSE_MIRACAST": "在电视屏幕上选择Miracast",
  "TABLET_WIRELESS_DISPLAY_SET_ON": "等待直到平板电脑设置时开启无线显示器后搜索Miracast",
  "INPUT_PIN_ON_TV": "如果需要，在平板电脑上输入电视上显示的PIN码",
  "TV_SCREEN_ON_TABLET_SUCCESS": "如果在电视屏幕上显示平板电脑屏幕，则表示连接成功",
  "QUESTION_TO_ADMIN": "求问经理",
  "IF_DO_NOT_CONNECT_CANNOT_RUNNINGCLASS_ARE_YOU_SURE": "如果没有和显示器连接，则无法继续上课。稍后继续？",
  "RESULT_CHECK": "检查记录",
  "CLASS_TYPE_DUPLICATE": "클래스 유형 명 이 중복됩니다. 다시확인해주세요.",
  //중국어 번역 없음
  "CLASS_TYPE_REMOVE_MSG": "删除课程类型?",
  "CLASS_TYPE_REMOVE_MSG2": "它将覆盖现有数据",
  "CLASS_TYPE_MODIFY_MSG": "修改课程类型？",
  "CLASS_TYPE_MODIFY_MSG2": "它将覆盖现有数据",
  "HEALTHONE_ADDR": "桑纳克、伊尔桑索古、戈扬斯、Gyeonggido、韩国",
  "HERAFIT_BUY_POPUP_IMG": "img_band_ch.png",
  "HERAFIT_BTN_BUY": "btn_buy_ch.png",
  "NAME_AND_BIRTHDAY_MISMATCH": "名字和生日必须一致。检查并重新输入。",
  "SEND_EMAIL": "通过电子邮件发送"
}
