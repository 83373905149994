<template>
  <ResourcePage
    :query="query"
    :search-keyword="search"
    :search-keys="['name', 'email']"
  >
    <template #title>
      {{ $t('리시버 설정') }}
    </template>
    <template #description>
      {{ $t('리시버를 설정할 수 있습니다.') }}
    </template>
    <template #list-item="scope">
      <div class="list-item">
        <i class="img-icon trainer"></i>
        <span v-html="toHighlight(search, scope.row.name)" />
      </div>
    </template>
    <template #detail>
      <ReceiverForm />
    </template>
  </ResourcePage>
</template>

<script>
import ResourcePage from '@/components/ResourcePage'
import MixinFormatUtils from '@/lib/MixinFormatUtils'
import ReceiverForm from '@/views/pages/setup/ReceiverForm'
export default {
  name: 'ReceiverPage',
  mixins: [MixinFormatUtils],
  components: { ReceiverForm, ResourcePage },
  props: ['id', 'schema'],
  data() {
    return {
      query: {},
      search: ''
    }
  },
  methods: {
    loadOptions() {
      this.$apiServer.get('api/v1/receiver_infos.json').then(response => {
        this.classes = response.data
      })
    }
  },
  mounted() {
    this.loadOptions()
  }
}
</script>

<style scoped lang="scss"></style>
