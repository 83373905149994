export default {
  "MENU_HOME": "Home",
  "MENU_HERAFIT": "HERA Fit+ Management",
  "MENU_TRANINER": "Trainer Management",
  "MENU_MEMBER": "Member Management",
  "MENU_CLASS": "Class Management",
  "MENU_HISTORY": "History",
  "MENU_SETUP": "Setting",
  "MENU_SETUP_USER": "사용자 정보",
  "MENU_SETUP_RECEIVER": "리시버 설정",
  "MENU_SETUP_USAGE": "사용 안내",
  "MENU_SETUP_LOGO": "로고 변경",
  "MENU_SETUP_APP": "앱 정보",
  "TITLE_DASHBOARD": "HERA Fit ON",
  "TITLE_SUBTITLE": "HERA Fit ON for Extremly Effective Workouts",
  "TITLE_CLASSMANAGE": "Class Management",
  "DASHBOARD_REGIST_CLASS": "List of Registered Classes",
  "DASHBOARD_RECENT_CLASS": "Classes Recently Done",
  "DASHBOARD_AVG_CAL_BURN": "Avg. Consumed Calories",
  "DASHBOARD_AVG_HEART_RATE": "Avg. Heart Rate",
  "DASHBOARD_TOTAL_MEMBER": "Entire Members",
  "DASHBOARD_TOTAL_TRANINER": "Entire Trainers",
  "DASHBOARD_TOTAL_HERAFIT": "Entire HERA Fit+",
  "LOGIN_EMAIL": "Email ID",
  "LOGIN_PW": "Password",
  "LOGIN_SAVEID": "Save ID",
  "LOGIN_FINDINFO": "Search for member information",
  "LOGIN": "Log-in",
  "LOGIN_REGIST": "Register manager",
  "FIND_USERINFO": "Search for member information",
  "FIND_ID": "Search for ID",
  "FIND_PW": "Search for password",
  "FIND_ID_GUIDE": "Enter information of Trainer to find ID<br/>※ Contact ceo@ehealth-one.com for Manager information.",
  "FIND_ID_TYPE": "Type",
  "FIND_ID_CHECK": "ID check",
  "FIND_PW_GUIDE": "Enter information of Trainer to find password<br/>※ Contact ceo@ehealth-one.com for Manager information.",
  "FIND_INPUT_PW_GUIDE": "Enter new password",
  "FIND_ID_INPUT": "Enter Email ID",
  "FIND_NAME_INPUT": "Enter name",
  "NEW_PASSWORD_REGIST": "New password register",
  "TRAINNER": "Trainer",
  "ADMIN": "Manager",
  "ID": "ID",
  "EMAIL": "Email",
  "NAME": "Name",
  "CLUB_PHONE_NUM": "Telephone Number of gym",
  "CLUB_NAME": "Name of Gym",
  "CLUB_LOCATION": "Location of Gym",
  "ONLY_INPUT_NUMBER": "Enter Numbers Only",
  "CONFIRM": "OK",
  "CANCEL": "Cancel",
  "PASSWORD": "Password",
  "PASSWORD_INPUT": "Enter Password",
  "PASSWORD_INPUT_CHECK": "Re-enter Password",
  "PASSWORD_CONDITION": "more than 6 characters mixed with English and numbers",
  "DUP_CHECK": "Check if duplicated",
  "REGIST_STEP1_GUIDE": "Use after registration as manager",
  "REGIST_STEP2_GUIDE": "Register basic information of manager",
  "REGIST_STEP3_GUIDE": "Register information of gym as well",
  "REGIST_FINISH_GUIDE": "Registration of manager complete",
  "REGIST_FINSIH_TEXT": "Proceed the followings after logging-in as manager",
  "REGIST_STEP1_TEXT": "Terms and Conditions agreement",
  "REGIST_STEP2_TEXT": "Enter basic information",
  "REGIST_STEP3_TEXT": "Enter information of gym",
  "REGIST_AGREE_GUIDE": "Agree with Right of Use to initiate service",
  "REGIST_I_AGREE": "Agreement of Right of Use",
  "REGIST_I_WILL_AGREE": "Agree to Terms and Conditions",
  "REGIST_MUST_AGREE": "Agree with terms first",
  "REGIST_INFO_INPUT": "Register basic information of manager",
  "REGIST_CLUB_INPUT": "Register information of gym as well",
  "REGIST_CLUB_NAME_INPUT": "Enter Name of Gym",
  "REGIST_LOCATION_INPUT": "Enter Location of Gym",
  "REGIST_MEM": "Member registration",
  "REGIST_CLASS": "Class registration",
  "REGIST_TRAINNER": "Trainer registration",
  "MATCHING": "Matching",
  "REGIST_START_HEALTH": "Begin Workout",
  "SHOW_ALL": "View all",
  "TOTAL_CLASS": "Entire Class",
  "SHOW_DEVICE_TYPE": "View by Equipment",
  "THREAD_MIL": "Tredmill",
  "WORKROW": "WalkRo",
  "CYCLE": "Bike",
  "CHALLENGE": "도전 몸짱", //영어 번역 없음
  "SHOW_BY_TYPE": "View by Type",
  "PLS_REGIST_CLASS": "Register Class",
  "NAME_SEARCHING": "Search for Name",
  "NO_REGIST_CLASS": "No class registered.",
  "CLASS_ADD": "Add Class",
  "AFTER_MEMBER_MATCHING": "Match Member with Trainer after registering class.",
  "REMOVE": "Delete",
  "ADD": "Add",
  "CLASS_DEVICE": "Equipment of Class",
  "CLASS_TYPE": "Class Type",
  "CLASS_NAME": "Class Name",
  "TARGET_HEART_BEAT": "Target Heart Rate",
  "MATCHING_MEMBER": "Members to Match",
  "LIMIT_30": "Max. 30 people",
  "RUNNING_TIME": "Elapsed Time",
  "MIN": "Minutes",
  "MATCHING_TRAINNER": "Trainer to be matched",
  "CHOOSE_MEMBER": "Select Member",
  "CHOOSE_TRAINNER": "Select Trainer",
  "INPUT_CLASS_NAME": "Enter Name",
  "CHOOSE_DEVICE": "Select Equipment",
  "CHOOSE_TYPE": "Select Type",
  "CHOOSE_HEART_BEAT": "Select Heart Rate",
  "CHOOSE_TIME": "Select Time",
  "INPUT_HEART_BEAT_PER": "Enter Heart Rate %",
  //"CLASS_LIST" = "List of Class",
  "MANAGER_TRAINNER": "Trainer to be matched",
  "TOTAL_CLASS_MEMBER": "Total Participants",
  "HEALTH_TIME": "Workout Duration",
  "AVG_MAX_HEART_BEAT": "Avg. Maximum Heart Rate",
  "MATCHING_MEMBER_LIST": "List of Member Matching",
  "TOTAL_BURN_CARORIES": "Total Consumed Calories",
  "GOOD_HEART_RATE": "Optimum Heart Rate",
  "MAX_HEART_BEAT": "Maximum Heart Rate",
  "HOLDING_TIME": "Holding Time",
  "HEART_BEAT_GRAPH": "Heart Rate Distribution Graph",
  "CLASS_MEMBER": "Participants",
  "PERSON": "people",
  "SEND_FIT_INFO": "Deliver",
  "START": "Start",
  "YOUR_ID": "ID is ",
  "ID_IS": " ",
  "ID_LOGIN_PLZ": "Try to log in with the same ID.",
  "ID_AND_NAME_NOT_MATCH": "<span class=\"text-orange\"> ID and Name</span>must coincide.",
  "CHECK_AND_INPUT": " Check and re-enter",
  "HERAFIT_MANAGE": "HERA Fit+ Management",
  "PLS_REGIST_HERAFIT": "Register HERA Fit+",
  "NO_REGIST_HERAFIT": "No Registered HERA Fit+.",
  "HERAFIT_ADD": "Add HERA Fit+",
  "HERAFIT_MODIFY": "Modify HERA Fit+",
  "AND_MEMBER_MATCHING": " and match it with Member.",
  "TOTAL_HERAFIT": "Entire HERA Fit+",
  "SERIAL_NO": "Serial Number",
  "OWN_TYPE": "Type of Owning",
  "HERAFIT_NAME": "Name of HERA Fit+",
  "CLUB_RENTAL": "Gym-rental",
  "PERSONAL_OWN": "Private-owned",
  "COMPLETE": "Complete",
  "INPUT_COMPLETE": "입력 완료", // 영어 번역 없음.
  "MODIFY_COMPLETE": "수정 완료", // 영어 번역 없음.
  "SHOW_CLUB_RENTAL": "View Gym-rental",
  "SHOW_PERSONAL_OWN": "View Private-own",
  "CERTI_DATE": "Verified Date",
  "CERTI_COMPELETE": "Verification Complete",
  "MODIFY": "Modify",
  "TRAINNER_MANAGE": "Trainer Management",
  "MEMBER_MANAGE": "Member Management",
  "CLASS_MATCHING_COMP_SHOW": "View Complete Class Matching",
  "CLASS_MATCHING_NOTCOMP_SHOW": "View Class Matching Incomplete",
  "TOTAL_TRAINER": "Entire Trainers",
  "PLS_REGIST_TRAINNER": "Register Trainer",
  "NO_REGIST_TRAINNER": "No registered trainer. ",
  "TRAINNER_ADD": "Trainer registration",
  "AND_CLASS_MATCHING": "and match them with HERA Fit+",
  "NAME_EMAIL_SEARCHING": "Name / Email Search",
  "REGIST_DATE": "Registered Date",
  "GENDER": "Sex",
  "HEIGHT": "Height",
  "WEIGHT": "Weight",
  "BIRTHDAY": "Birthdate",
  "MATCHING_CLASS": "Class Matching",
  "MALE": "Male",
  "FEMALE": "Female",
  "PLZ_INPUT_EMAIL": "Enter Email Address",
  "CHOICE": "Select",
  "PLS_REGIST_MEMBER": "Register Member",
  "TOTAL_MEMBER": "Entire Members",
  "MEMBER_ADD": "Add Member",
  "MEMBER_MODIFY": "Modify Member",
  "NO_REGIST_MEMBER": "No registered member.",
  "AFTER_HERAFIT_MATCHING": "Add and match them with HERA Fit+",
  "EMAILNOTEXIST": "Able to Register",
  "EMAILDUPLICATE": "Check if already existing",
  "IS_NOT_EMAIL_ADDRESS": "Email address not correct",
  "PASSWORD_POLICY": "Enter more than 6 characters mixed with letters and numbers",
  "NOT_MATCH_PASSWORD": "Password incorrect",
  "PLZ_INPUT_CLUB_TELNO": "클럽 전화번호를 입력하세요.", // 영어 번역 없음.
  //"SAVE_FAIL": "저장 실패", // 영어 번역 없음.
  "LOGIN_NEEDS_JOIN": "Use after registration as manager",
  "PASSWORD_FAIL": "Password incorrect",
  "NO_MANAGER_INFO": "관리자 정보가 존재 하지 않습니다", // 영어 번역 없음.
  "PLZ_INPUT_BIRTHDAY": "Enter birthdate",
  "NO_ID_SEARCH": "해당 정보를 찾을수 없습니다.", // 영어 번역 없음.
  "PASSWORD_CHANGED": "비밀번호가 변경되었습니다.", // 영어 번역 없음.
  "FAIL": "Failed",
  "PLZ_INPUT_HEIGHT": "Enter height",
  "PLZ_INPUT_WEIGHT": "Enter weight",
  "ARE_YOU_SURE_TRAINER_REMOVE": "Delete selected Trainer?",
  "ARE_YOU_SURE_HERAFIT_REMOVE": "Delete Selected HERA Fit+?",
  "REMOVE_COMPLETE": "삭제 되었습니다.", // 영어 번역 없음.
  "NO_SEARCH_RESULT": "No result",
  "WEIGHT_TRAINING": "Strength Training",
  "NO_TOOL": "No Equipment",
  "LIGHT_EXERCISE": "Light Workout",
  "OXYGEN_EXERCISE": "Cardio Workout",
  "OXYGEN_FREE_EXERCISE": "Anaerobic Workout",
  "OXYGEN_AND_FREE_EXERCISE": "Cardio/Anaerobic Complex Workout",
  "DIET_EXERCISE": "Fat Burn Workout",
  "CALORIE_GOAL_EXERCISE": "Calorie Target Workout",
  "HEART_RATE_GOAL_EXERCISE": "Heart Rate Target Workout",
  "TIME_GOAL_EXERCISE": "Time Target Workout",
  "HERA_ZONE": "Hera Zone",
  "FREE_EXERCISE": "Free Training",
  "PLZ_INPUT_TIME": "Enter Time",
  "WARMING_UP": "Warm-up",
  "FAT_BURNING": "Fat Burn",
  "IMPROVE_ENDURANCE": "Improve Cardiovascular Endurance",
  "MAX_EXERCISE_EFFECT": "Maximum Workout Result",
  "MAX_STRANGTH": "Maximum Intensity",
  "MANUAL_INPUT": "Input yourself",
  "PLZ_CHOOSE_DEVICE": "클래스 기구를 선택해주세요.", // 영어 번역 없음
  "PLZ_CHOOSE_TYPE": "클래스 유형을 선택해주세요",  // 영어 번역 없음
  "PLZ_CHOOSE_HEART_BEAT": "Enter Target Heart Rate",
  "PLZ_CHOOSE_TIME": "Enter Workout Time",
  "PLZ_CHOOSE_MEMBER": "Select Member",
  "PLZ_CHOOSE_TRAINER": "Select Trainer",
  "CHECKED_TRAINER": "Trainer Selected",
  "CHECKED_MEMBER": "Member selected",
  "MAN_COUNT": "people",
  "CHOOSE_TRAINER_GUIDE": "Matches Trainer to be in charge of Class",
  "HEALTH_RESULT": "Workout Summary",
  "ARE_YOU_SURE_CLASS_REMOVE": "Delete selected Class? ",
  "ARE_YOU_SURE_USER_REMOVE": "Do you really want to delete this member?",
  "CLASS_MODIFY": "Modify Class",
  "HEART_RATE_GUIDE": "Auto-calculates after entering height, weight, and birthdate",
  "CHOOSE_MEMBER_GUIDE": "Designate HERA Fit+ to use and member to join class",
  "CHOOSE_HERAFIT": "Select HERA Fit+",
  "PLZ_MATCHING_MEMBER_TO_DEVICE": "Match Member with HERA Fit+",
  "SETUP": "Setting",
  "ADMININFO": "User Information",
  "HOWTOUSE": "User Guide",
  "LOGO_CHANGE": "Logo Change",
  "APP_GUIDE": "App Guide",
  "LOGOUT": "Logout",
  "ADMIN_MODIFY": "Modify Manager",
  "LANGUAGE_SETTING": "Select Language",
  "PREV": "Previous",
  /** DEVICE 관련 */
  "DEVICE_SN_CHECK_OK": "Able to Register",
  "DEVICE_SN_BLANK_ERROR": "Enter Serial Number",
  "DEVICE_SN_OVERLAP_ERROR": "Already registered. Try other serial number.",
  "DEVICE_NAME_CHECK_OK": "Able to Register",
  "DEVICE_NAME_BLANK_ERROR": "Enter Name of HERA Fit+",
  // 사용 안함
  "DEVICE_NAME_OVERLAP_ERROR": "Duplicated name. Re-enter.",
  "DEVICE_DUPLICATE_MODE_ERROR": "Unable to confirm criteria of duplicate search ",
  "DEVICE_DUPLICATE_TYPE_ERROR": "Unable to confirm type of device",
  /** 2018.07.09 이후 추가분 */
  "CLASS_SETTING": "Class Settings",
  "CLASS_SETTING_GUIDE": "Set Equipment and Type when adding/modifying Class",
  "TYPE_MANAGING": "Type Management",
  "EQUIPMENT_MANAGING_BY_EXCERSISE": "Workout Management by Equipment",
  "HERA_ZONE_GUIDE": "This will keep buring your fat for maximum 36 hours if having maintained more than 12 minutes at 84% of your maximum heart rate ",
  "PLZ_INPUT_CLASS_TYPE": "Enter Name of Type",
  "PLZ_INPUT_EQUIPMENT_NAME": "Enter Name of Equipment",
  "TRAINNER_MODIFY": "Modify Trainer",
  "RANKING": "Place",
  "UPLOAD": "Upload",
  "CHOOSE_FILE": "Choose file",
  "LOGO_UPLOAD_GUIDE": "- White background will come up if background of image file is not transparent.<br>- Optimum image size is width 500px x length 400px. If not fit, it will be automatically altered.",
  "LOGO_GUIDE": "Change logo exposed while displayed",
  "CANNOT_USE_IMAGE_FILE": "Unable to upload. Use jpg, jpeg, png, and bmp below size of 10 MB",
  "PLZ_LOGO_FILE_UPLOAD": "Upload Preferred Logo.",
  "ANY_QUESTION": "Got something to say?",
  "MENU_HOME_GUIDE": "This is Dashboard you can check and manage information from App",
  "MENU_HERAFIT_GUIDE": "Manages and registers HERA Fit+ by Gym-rentals and Private-owns.<br>Registering new HERA Fit+ needs its serial number at the back.",
  "MENU_TRAINER_GUIDE": "Registers, modifies, and deletes trainers managing entire Classes.<br>Email of Trainer will be used as ID when loggin in. Register carefully.",
  "MENU_MEMBER_GUIDE": "Registers Members that join Class, Optimum heart rate will be auto-calculated according to their physical data. To also be emailed, enter precise data.",
  "MENU_CLASS_GUIDE": "Able to begin after matching Members with Trainer. <br>Check Member's workout data in real time after display linkage. Any Equipment and Type for Class can be chosen. <br><div class=\"red\">- Data will be auto-piled up after Class. </div>",
  "ALREADY_CLASS_EXIST": "Class in Progress Already Existing",
  "PAUSE_CLASS_EXIST": "Paused Class Existing",
  "STOP": "Pause",
  "RESTART": "Restart",
  "FINISH": "Done",
  "PLZ_CHOOSE_MEMBER_IN_CLASS": "Select Member to join Class",
  "ARE_YOU_SURE_EXCERCISE_STOP": "Finish Class? Last workout is saved as recent workout record.",
  "USERINFO": "User Information",
  "DISPLAY_CONNECT_GUIDE": "Display Linkage Guide",
  "PERMISSION_GUIDE": "Authority Guide",
  "HERAFIT_USE_PERMISSION_GUIDE": "It's Guide for Required Access Authority to use HERA Fit ON",
  "REQUIRE_PERMISSION": "Required Access Authority",
  "WIFI_CONNECT_INFO": "Wi-Fi Connection Information",
  "USE_APP_CHECK_NETWORK": "Check network information while using App",
  "BLUETOOTH_CONNECT_INFO": "Bluetooth connection information",
  "BLUETOOTH_GUIDE": "Control collecting connection information or transmitting it to near Bluetooth devices",
  "MEDIA/FILE": "Media / File",
  "FILE_PERMISSION_GUIDE": "Upload after accessing saved media files",
  "CONNECT_GUIDE": "Linkage Guide",
  "CAN_USE_HERAFIT_CONNECT": "Use HERA Fit ON after linking.",
  "CHOOSE_AND_FALLOW_GUIDE": "Proceed according to guideline after choosing item.",
  "YOU_CAN_SEE_SETUP_MENU_TOO": "The guideline can be viewed at Settings",
  "DISPLAY_SHARE_CONNECT_APP": "Connects app and large display that guides workout information during class",
  "DISPLAY_AND_SERVER": "Display - Server Linkage Guide",
  "HOWTO_SERVER_CONNECT": "It's linkage through server. Proceed according to guideline below.",
  "SERVER_TURN_ON": "Switch on server",
  "TV_INPUT_TYPE_SET": "Set TV input mode ",
  "INPUT_OR_SETUPBOX": "external input or set top box",
  "TV_READY_CONNECT_SUCCESS": "Linkage successful when TV",
  "DISPLAY_APP_CONNECT": "Display - App Linkage",
  "MIRRORING_CONNECT": "Connect screen mirroring",
  "CONNECT_COMPLETE": "Linkage complete",
  "MAYBE_LATER": "Proceed later",
  "NEXT": "Next",
  "DISPLAY_MIRRORING_CONNECT_GUIDE": "Display - Screen Mirroring Linkage Guide",
  "IF_HARD_TO_CONNECT_FALLOW_THIS_GUIDE": "Proceed mirroring setting ",
  "INPUT_TV_DONGLE": "Insert dongle into TV",
  "TURN_ON_DONGLE": "Connect power to dongle",
  "TURN_ON_TV_AND_INPUT_TYPE_CHOOSE": "Select HDMI after switching on TV",
  "TV_SCREEN_CHOOSE_MIRACAST": "Select Miracast on TV screen",
  "TABLET_WIRELESS_DISPLAY_SET_ON": "Wait until Miracast is searched after turning on wireless display at tablet setting",
  "INPUT_PIN_ON_TV": "Enter PIN code displayed on TV onto tablet, if needed",
  "TV_SCREEN_ON_TABLET_SUCCESS": "If tablet screen is show on TV screen, it's successful linkage",
  "QUESTION_TO_ADMIN": "Inquire of Manager",
  "IF_DO_NOT_CONNECT_CANNOT_RUNNINGCLASS_ARE_YOU_SURE": "Unable to proceed class if not linked with display. Proceed later?",
  "RESULT_CHECK": "Check Record",
  "CLASS_TYPE_DUPLICATE": "클래스 유형 명 이 중복됩니다. 다시확인해주세요.", // 영어 번역 없음
  "CLASS_TYPE_REMOVE_MSG": "Delete Type of Class?",
  "CLASS_TYPE_REMOVE_MSG2": "It will overwrite existing data.",
  "CLASS_TYPE_MODIFY_MSG": "Modify Type of Class?",
  "CLASS_TYPE_MODIFY_MSG2": " It will overwrite existing data.",
  "HEALTHONE_ADDR": "132,Sannam-ro,Ilsanseo-gu,Goyang-si,Gyeonggi-do,Rep.of Korea",
  "HERAFIT_BUY_POPUP_IMG": "img_band_en.png",
  "HERAFIT_BTN_BUY": "btn_buy_en.png",
  "NAME_AND_BIRTHDAY_MISMATCH": "Name and Birthdate must coincide. Check and re-enter.",
  "SEND_EMAIL": "Sent by Email"
}

