/* eslint-disable no-undef */
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Element from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import App from './App.vue'
import VueMq from 'vue-mq'
import store from './store'
import i18n_messages from './locales'
import * as axios from 'axios'
import router from './router/'
import ElementUI from 'element-ui'
import '@/assets/scss/global.scss'
import '@/assets/scss/element-variables.scss'
import VCalendar from 'v-calendar-ie11';

Vue.config.productionTip = false

Vue.use(VueMq)
Vue.use(VueI18n)
Vue.use(Element, { locale })

const EventBus = new Vue()

const shared = {
  user: {
    is_authenticated: false
  }
}

const i18n = new VueI18n({
  locale: 'ko',
  messages: i18n_messages,
  silentTranslationWarn: true
})

const apiServer = axios.create({
  baseURL: APP_CONFIG.apiBaseUrl,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

apiServer.interceptors.request.use(
  function(config) {
    const authToken = window.sessionStorage.getItem('auth_token')
    if (authToken) {
      config.headers[config.method] = {
        Authorization: `Token ${authToken}`
      }
    }
    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)

shared.install = function() {
  Object.defineProperty(Vue.prototype, '$apiServer', {
    get() {
      return apiServer
    }
  })
  Object.defineProperty(Vue.prototype, '$eventBus', {
    get() {
      return EventBus
    }
  })
  Object.defineProperty(Vue.prototype, '$urlBase', {
    get() {
      return APP_CONFIG.apiBaseUrl
    }
  })
}

Vue.use(VCalendar)

Vue.use(shared)

Vue.use(ElementUI, { size: 'small' })

Vue.use(VueMq, {
  breakpoints: {
    mobile: 541,
    tablet: 769,
    laptop: 993,
    desktop: Infinity
  }
})

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
