<template>
  <div class="setup-contents">
    <div class="text-guide-title">
      {{ $t('LOGO_GUIDE') }}
    </div>
   
      <div class="img-wrap">
        <img
          v-if="user.shop && user.shop.logo"
          :src="user.shop.logo"
          width="250"
          height="200"
          class="logo-image"
        />
        <img
          v-else
          width="250"
          height="200"
          class="logo-image"
          src="~@/assets/img/logo.png"
        />
        <div>
        <input type="file" id="logoChange" name="logoChange" @change="previewFile">
      </div> 
      </div>
      
      <div class="demo-color-block">
        <el-color-picker v-model="user.shop.theme_color" />
        <span class="demonstration">배경색상 변경</span>
        
      </div>
      <div class="demo-color-block">
        <el-color-picker v-model="user.shop.level1_color" />
        <span class="demonstration">레벨1(0 ~ 59% 워밍업)</span>
      </div>
      <div class="demo-color-block">
        <el-color-picker v-model="user.shop.level2_color" />
       <span class="demonstration">레벨2(60 ~ 69% 지방연소)</span>
      </div>
      <div class="demo-color-block">
        <el-color-picker v-model="user.shop.level3_color" />
        <span class="demonstration">레벨3(70 ~ 83% 심폐 지구력 향상)</span>
      </div>
      <div class="demo-color-block">
        <el-color-picker v-model="user.shop.level4_color" />
        <span class="demonstration">레벨4(84 ~ 91% 최대 운동 효과)</span>
      </div>
      <div class="demo-color-block">
        <el-color-picker v-model="user.shop.level5_color" />
        <span class="demonstration">레벨5(92 ~ 100% 최대 강도)</span>
      </div>
    <!-- <div class="text-guide-content " v-html="$t('LOGO_UPLOAD_GUIDE')" /> -->
    <div class="text-right">
      <button  type="button" @click="goSave" class="el-button el-button--primary">
        <i class="el-icon-check"></i>
        <span>저장 </span>
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex'
export default {
  name: 'Logo',
  data() {
    return {
      images: null
    }
  },
  computed: {
    ...mapGetters(['user']),
    id() {
      return this.$route.params.id
    },
    resource() {
      return this.$route.meta && this.$route.meta.resource
    },
  },
  methods: {
   
    previewFile(e){
      
      let file = e.target.files;
      let url = URL.createObjectURL(file[0]);
      this.user.shop.logo = url
      this.images = file[0];
    },
    goSave(){
     const frm = new FormData()
      if(this.images){
      frm.append("logo", this.images)
    }
      frm.append("theme_color", this.user.shop.theme_color)
      frm.append("level1_color", this.user.shop.level1_color)
      frm.append("level2_color", this.user.shop.level2_color)
      frm.append("level3_color", this.user.shop.level3_color)
      frm.append("level4_color", this.user.shop.level4_color)
      frm.append("level5_color", this.user.shop.level5_color)
      

      

      axios.put(`https://api.herafiton.com/api/v1/shops/${this.user.shop.id}.json`, frm, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(() => {
        this.$alert(
        this.$t(
          '저장되었습니다.'
        ),
        {
          confirmButtonText: this.$t('CONFIRM')
        }
      ).then(() => {
      //   this.$router.push({
      //   name: 'setup.logo'
      // })
      })
      })
      
    }
  },
  mounted() {
    
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/_variables.scss';

.text-guide-title {
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 40px;
}
img.logo-image {
  border-radius: 10px;
  overflow: hidden;
  border: solid 1px #cccccc;
  object-fit: contain;
}

.upload-wrap {
  margin-bottom: 35px;
  color: $text-color;
  ::v-deep {
    .el-upload {
      text-align: left;
    }

    .el-button {
      background-color: #f1f1f1;
      border: solid 1px $light-grey;
      font-size: 18px;
      padding: 6px 24px;
      line-height: 28px;
      ~ span {
        margin-left: 10px;
      }
    }
    
  }
}
.demo-color-block {
  margin-top: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
}
.demo-color-block .demonstration {
  margin-left: 15px;
}

  

.text-guide-content {
  font-size: 18px;
  line-height: 1.39;
  color: $text-grey2;
}
</style>
