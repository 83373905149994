<template>
  <ResourcePage
    :query="query"
    :search-keyword="search"
    :search-keys="['name', 'email']"
  >
    <template #filter>
      <el-form label-position="top">
        <el-row :gutter="4">
          <el-col>
            <el-form-item>
              <el-select v-model="query.has_class">
                <el-option :value="null" :label="$t('SHOW_ALL')"></el-option>
                <el-option
                  :value="1"
                  :label="$t('CLASS_MATCHING_COMP_SHOW')"
                ></el-option>
                <el-option
                  :value="0"
                  :label="$t('CLASS_MATCHING_NOTCOMP_SHOW')"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item>
            <el-input
              :placeholder="$t('NAME_EMAIL_SEARCHING')"
              suffix-icon="el-icon-search"
              v-model="search"
            >
            </el-input>
          </el-form-item>
        </el-row>
      </el-form>
    </template>
    <template #list-item="scope">
      <div class="list-item">
        <i class="img-icon trainer"></i>
        <span v-html="toHighlight(search, scope.row.email)" />
      </div>
    </template>
    <template #detail>
      <TrainerForm />
    </template>
  </ResourcePage>
</template>

<script>
import TrainerForm from '@/views/pages/TrainerForm'
import ResourcePage from '@/components/ResourcePage'
import MixinFormatUtils from '@/lib/MixinFormatUtils'
export default {
  name: 'TrainerPage',
  mixins: [MixinFormatUtils],
  components: { ResourcePage, TrainerForm },
  props: ['id', 'schema'],
  data() {
    return {
      query: {
        has_class: null
      },
      search: ''
    }
  },
  methods: {
    loadOptions() {
      this.$apiServer.get('api/v1/classes.json').then(response => {
        this.classes = response.data
      })
    }
  },
  mounted() {
    this.loadOptions()
  }
}
</script>

<style scoped lang="scss"></style>
