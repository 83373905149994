<template>
  <div class="status-card gradient" :class="user.classes" :style="customStyle">
    <div class="label">
      <div class="name">
        {{ user.name }}
      </div>
      <div v-if="user.best_label" class="best">
        {{ user.best_label }}
      </div>
    </div>
    <div class="card-body" v-if="type == 1">
      <div class="sub">
        <p>
          <i class="icon icon-haert" />
          <span>{{ (user.percent && `${user.percent}`) || '--' }}%</span>
        </p>
        <p>
          <i class="icon icon-calroies" />
          <span>{{ (Math.round(user.calories/3) && `${Math.round(user.calories/3)}`) || '--' }}</span>
        </p>
      </div>
      <div class="main">
        <span>{{ (user.hr && `${user.hr}`) || '--' }}</span>
      </div>
    </div>
    <div class="card-body" v-if="type == 2">
      <div class="sub">
        <p>
          <i class="icon icon-haert" />
          <span>{{ (user.hr && `${user.hr}`) || '--' }}</span>
        </p>
        <p>
          <i class="icon icon-calroies" />
          <span>{{ (Math.round(user.calories/3) && `${Math.round(user.calories/3)}`) || '--' }}</span>
        </p>
      </div>
      <div class="main">
        <span class="percent"
          >{{ (user.percent && `${user.percent}`) || '--'
          }}<span class="unit">%</span></span
        >
      </div>
    </div>
    <div class="card-body" v-if="type == 3">
      <div class="sub">
        <p>
          <i class="icon icon-haert" />
          <span>{{ (user.hr && `${user.hr}`) || '--' }}</span>
        </p>
        <p>
          <i class="icon icon-haert" />
          <span>{{ (user.percent && `${user.percent}`) || '--' }}%</span>
        </p>
      </div>
      <div class="main">
        <i class="icon icon-calroies-large" />
        <span class="calories">{{
          (Math.round(user.calories/3) && `${Math.round(user.calories/3)}`) || '--'
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserCard',
  props: ['user', 'type', 'shop'],
  computed: {
  customStyle () {
    return {
      "--level1": this.shop.level1_color,
      '--level2': this.shop.level2_color,
      '--level3': this.shop.level3_color,
      '--level4': this.shop.level4_color,
      '--level5': this.shop.level5_color
      

    }
  }
  }
}
</script>

<style scoped lang="scss">
i.icon {
  display: inline-block;
  content: '';
}
i.icon-haert {
  width: 20px;
  height: 20px;
  margin-top: -2px;
  margin-right: 7px;
  background-image: url('~@/assets/icons/icon-heart.png');
  background-size: 20px 20px;
}
i.icon-calroies {
  width: 23px;
  height: 23px;
  margin-top: -5px;
  margin-left: -1px;
  margin-right: 5px;
  background-image: url('~@/assets/icons/icon-fire.png');
  background-size: 23px 23px;
}
.status-card {
  width: 100%;
  height: 190px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  color: white;
  &.inactive {
    opacity: 0.3;
  }
  .label {
    height: 47px;
    border-radius: 10px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16);
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .name {
      line-height: 37px;
      font-size: 25px;
      font-weight: 500;
    }
    .best {
      font-size: 17px;
      font-weight: bold;
      font-style: italic;
    }
  }
  .card-body {
    padding: 10px 20px;
    flex: 1;
    display: flex;
    flex-direction: row;
    font-family: GmarketSans, 'Noto Sans Korean', sans-serif;
    > * {
      display: flex;
      &.sub {
        min-width: 85px;
        flex-direction: column;
        padding-bottom: 14px;
        justify-content: flex-end;
        p {
          font-size: 20px;
          font-weight: 500;
          line-height: 23px;
          margin: 0 0 6px;
          > * {
            vertical-align: middle;
          }
        }
      }
      &.main {
        word-break: keep-all;
        flex: 1;
        padding-bottom: 5px;
        text-shadow: 5px 5px 3px rgba(0, 0, 0, 0.3);
        font-weight: 700;
        font-size: 90px;
        align-items: flex-end;
        justify-content: flex-end;
        span.percent {
          font-size: 80px;
          span.unit {
            font-size: 25px;
          }
        }
        i.icon.icon-calroies-large {
          display: inline-block;
          content: '';
          width: 40px;
          height: 74px;
          margin-top: -20px;
          background-image: url('~@/assets/icons/icon-fire-large.png');
          background-position: 0px 16px;
          background-size: 40px 48px;
          background-repeat: no-repeat;
        }
        span.calories {
          font-size: 64px;
        }
      }
    }
  }
}

.red {
  background-color: var(--level5);
}

.orange {
  background-color: var(--level4);
}

.green {
  background-color: var(--level3);
}

.blue {
  background-color: var(--level2);
}

.grey {
  background-color: var(--level1);
}
</style>
