<script>
import { Doughnut } from 'vue-chartjs'
import moment from 'moment'

export default {
  name: 'DoughnutChart',
  extends: Doughnut,
  props: {
    data: {
      type: Object,
      required: true,
      default: function() {
        return {}
      }
    },
    text: {
      type: String,
      default: function() {
        return ''
      }
    }
  },
  mounted: function() {
    const data = {
      labels: this.data.label,
      datasets: [
        {
          label: 'time(sec)',
          backgroundColor: this.data.color,
          data: this.data.time
        }
      ]
    }
    const options = {
      maintainAspectRatio: false,
      legend: { position: 'right', align: 'start' },
      tooltips: {
        callbacks: {
          label: function(tooltipItem, data) {
            const time =
              data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
            return `${data.labels[tooltipItem.index]}:  ${moment
              .utc(time * 1000)
              .format('HH:mm:ss')}`
          }
        }
      }
    }
    this.renderChart(data, options)
  }
}
</script>

<style scoped></style>
