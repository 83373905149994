<template>
  <el-dialog
    :visible="dialogvisible"
    @close="$emit('update:dialogvisible', false)"
  >
    <div class="text-center">
      <a @click="dialogvisible = false"
        ><img
          src="~@/assets/img/icon_close2.png"
          style="position: absolute; width: 2%; top: 2%; right: 3%; cursor: pointer;"
          alt="창닫기"
      /></a>
      <!--a href="http://smartstore.naver.com/ehealthone/products/2649196262" target="_blank"><img src="/dist/img/btn_buy.png" style="position: absolute; width: 21%; top: 38%; left: 51.5%;" alt="구매하기"/></a-->
      <img
        :src="images[$t('HERAFIT_BTN_BUY')]"
        style="position: absolute; width: 21%; top: 43%; left: 51.5%; cursor: pointer;"
        alt="구매하기"
        @click="onBuy"
      />
      <img :src="images[$t('HERAFIT_BUY_POPUP_IMG')]" width="100%" />
    </div>
  </el-dialog>
</template>

<script>
import img_band from '@/assets/img/img_band.png'
import img_band_ch from '@/assets/img/img_band_ch.png'
import img_band_jp from '@/assets/img/img_band_jp.png'
import img_band_en from '@/assets/img/img_band_en.png'
import btn_buy from '@/assets/img/btn_buy.png'
import btn_buy_ch from '@/assets/img/btn_buy_ch.png'
import btn_buy_jp from '@/assets/img/btn_buy_jp.png'
import btn_buy_en from '@/assets/img/btn_buy_en.png'

export default {
  name: 'HerafitBuy',
  props: ['dialogvisible'],
  data() {
    return {
      images: {
        'img_band.png': img_band,
        'img_band_ch.png': img_band_ch,
        'img_band_jp.png': img_band_jp,
        'img_band_en.png': img_band_en,
        'btn_buy.png': btn_buy,
        'btn_buy_ch.png': btn_buy_ch,
        'btn_buy_jp.png': btn_buy_jp,
        'btn_buy_en.png': btn_buy_en
      }
    }
  },
  methods: {
    onBuy() {
      window.open(
        'http://smartstore.naver.com/ehealthone/products/2649196262',
        '_blank'
      )
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ {
  .el-dialog {
    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      padding: 0;
    }
  }
}
</style>
