<template>
  <div class="setup-contents">
    <div class="user-header">
      <div class="shop-name">
        <span>{{ user.shop.name }}</span>
        <span class="label" style="font-size: small">{{
          user.auth == 1 ? $t('TRAINNER') : $t('ADMIN')
        }}</span>
      </div>
      <el-button icon="el-icon-edit">{{ $t('MODIFY') }}</el-button>
    </div>

    <div class="text-center mgt40">
      <table class="user-info">
        <colgroup>
          <col width="25%" />
          <col width="*" />
        </colgroup>
        <tr>
          <th>{{ $t('NAME') }}</th>
          <td class="text-left">{{ user.name }}</td>
        </tr>
        <tr>
          <th>{{ $t('EMAIL') }}</th>
          <td class="text-left">{{ user.email }}</td>
        </tr>
        <template v-if="true">
          <tr>
            <th>{{ $t('CLUB_NAME') }}</th>
            <td class="text-left">{{ user.shop.name }}</td>
          </tr>
          <tr>
            <th>{{ $t('CLUB_LOCATION') }}</th>
            <td class="text-left">{{ user.shop.address }}</td>
          </tr>
          <tr>
            <th>{{ $t('CLUB_PHONE_NUM') }}</th>
            <td class="text-left">{{ user.shop.phone }}</td>
          </tr>
        </template>
      </table>

      <div class="mgt20">
        <div class="pull-right">
          <!--                        <?php if($adminInfo['auth_code']==1){ ?>-->
          <!--                        <?php }else { ?>-->
          <!--                        <a href="/Setup/AdminModify/<?=$adminInfo['mngr_no']?>" class="text-orange text-underbar ftsm">{{$t('MODIFY')}}</a>-->
          <!--                        <?php }?>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'UserInfo',
  data() {
    return {
      user: {
        shop: {}
      }
    }
  },
  methods: {
    ...mapMutations(['setLogout']),
    logout() {
      this.$apiServer.post('auth/token/logout').then(() => {
        window.sessionStorage.removeItem('auth_token')
        this.setLogout()
        this.$router.push('/')
      })
    },
    loadData() {
      this.$apiServer.get('auth/users/me.json').then(response => {
        this.user = response.data
      })
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>

<style scoped lang="scss">
.user-header {
  display: flex;
  justify-content: space-between;
  height: 30px;
  margin-bottom: 16px;
  .shop-name {
    display: flex;
    align-items: flex-end;
    font-size: 20px;
    .label {
      margin-left: 10px;
      display: inline-block;
      background-color: #eb881c;
      border-radius: 5px;
      padding: 0 12px;
      height: 20px;
      line-height: 20px;
      font-size: 10px;
      color: white;
    }
  }
  .el-button {
    padding: 0 14px;
  }
}

table.user-info {
  font-size: 14px;
  margin-bottom: 100px;
  tr {
    height: 40px;
    > td,
    th {
      text-align: left;
      font-weight: normal;
    }
    > th {
      min-width: 160px;
    }
  }
}
</style>
