<template>
  <div class="class-report-wrap" v-if="detail">
    <div class="summary">
      <div>
        <div class="value">{{ toStartedTime(detail.started_at) }}</div>
        <div class="unit">{{ detail.name }}</div>
      </div>
      <div>
        <div class="value">{{ detail.details.length + '명' }}</div>
        <div class="unit">{{ $t('참여인원') }}</div>
      </div>
      <div>
        <div class="value">{{ toRunningTime(detail.running_time) }}</div>
        <div class="unit">{{ $t('운동시간') }}</div>
      </div>
      <div>
        <div class="value">{{ Math.round(detail.avg_hr) }}</div>
        <div class="unit">{{ $t('평균심박(BPM)') }}</div>
      </div>
      <div>
        <div class="value">{{ Math.round((detail.avg_calories/3)+6) }}</div>
        <div class="unit">{{ $t('평균 칼로리(Kcal)') }}</div>
      </div>
    </div>

    <el-collapse @change="onChangeCollapse">
      <el-collapse-item
        v-for="(d, i) in detail.details"
        :key="d.id"
        @click="updateDetail(d, i)"
        :name="i"
      >
        <template slot="title">
          <div class="item-title">{{ d.name }}</div>
        </template>
        <Report :detail="d" :show-title="false" />
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import MixinFormatUtils from '@/lib/MixinFormatUtils'
import Report from '@/components/Report'

export default {
  name: 'ClassReport',
  components: { Report },
  mixins: [MixinFormatUtils],
  props: ['detail'],
  computed: {
    chartData() {
      return {
        label: this.ranges.map(i => i.range),
        color: this.ranges.map(i => i.color),
        time: this.ranges.map((_, i) => this.detail[`range${i + 1}_time`])
      }
    }
  },
  data() {
    return {
      goal_type: {
        1: `0~59% ${this.$t('WARMING_UP')}`,
        2: `60~69% ${this.$t('FAT_BURNING')}`,
        3: `70~79% ${this.$t('IMPROVE_ENDURANCE')}`,
        4: `80~89% ${this.$t('MAX_EXERCISE_EFFECT')}`,
        5: `90~100% ${this.$t('MAX_STRANGTH')}`
      }
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    updateDetail(detail, index) {
      window.console.log(index)
    },
    onChangeCollapse(names) {
      names.forEach(n => {
        if (this.detail.details[n].hrs === undefined) {
          this.detail.details[n].hrs = null
          const d = this.detail.details[n]
          this.$apiServer
              .get(`api/v1/history/${d.workout_class_execution}/member/${d.member}/hr.json`)
              .then(response => {
                this.detail.details[n] = response.data
                this.$forceUpdate()
              })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/_variables.scss';

.report-wrap {
  display: flex;
  flex-direction: column;
}

.summary {
  background-color: #555555;
  padding: 30px 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  color: white;

  > div {
    text-align: center;
    flex: 1;
    flex-basis: 1px;

    > .unit {
      font-size: 16px;
    }

    > .value {
      margin-bottom: 5px;
      font-size: 20px;
    }
  }
}

.item-title {
  padding: 0 30px;
  font-size: 20px;
}
.el-collapse {
  border-top: none;
  border-bottom: none;
  .el-collapse-item {
    border-radius: 10px;
    border: solid 1px $border-grey;
    margin-bottom: 15px;
    overflow: hidden;
  }
  ::v-deep {
    .el-collapse-item__wrap,
    .el-collapse-item__header {
      border-bottom: none;
    }
  }
}
</style>
