import ko from './ko'
import en from './en'
import jp from './jp'
import cn from './cn'

export default {
  ko,
  en,
  jp,
  cn
}
