<template>
  <div class="signup-wrapper">
    <div class="logo-wrapper">
      <img class="logo" height="52px" src="~@/assets/img/logo.png" />
    </div>
    <el-steps :active="step" align-center>
      <el-step :title="$t('REGIST_STEP1_TEXT')"></el-step>
      <el-step :title="$t('REGIST_STEP3_TEXT')"></el-step>
      <el-step :title="$t('REGIST_STEP2_TEXT')"></el-step>
    </el-steps>
    <section class="content container-fluid ">
      <div class="mga15 text-center ftsm" :class="{ 'text-orange': step < 4 }">
        <template v-if="step == 4">
          <span class="text-orange ftsm">{{ $t('REGIST_FINISH_GUIDE') }}</span>
          <h3>{{ $t('REGIST_FINSIH_TEXT') }}</h3>
        </template>
      </div>

      <Step1
        v-if="step == 1"
        @next="
          data => {
            shop = { terms_agreement: data }
            step = 2
          }
        "
      />
      <Step3
        v-if="step == 2"
        @next="
          data => {
            shop = Object.assign(shop, data)
            step = 3
          }
        "
      />
      <Step2 :shop="shop" v-if="step == 3" @next="onRegistered" />
      <Final v-if="step == 4" />
    </section>
  </div>
</template>

<script>
import Step1 from '@/views/pages/signup/Step1'
import Step2 from '@/views/pages/signup/Step2'
import Step3 from '@/views/pages/signup/Step3'
import Final from '@/views/pages/signup/Final'
export default {
  name: 'SignUpPage',
  components: { Final, Step3, Step2, Step1 },
  data() {
    return {
      agreement: false,
      user: null,
      shop: null,
      step: 1
    }
  },
  methods: {
    onRegistered(data) {
      this.user = data
      this.$alert(
        this.$t(
          '헤라핏온 가입신청을 해주셔서 감사합니다.\n' +
            '본사 관리자 승인 후 사용가능합니다.'
        ),
        {
          confirmButtonText: this.$t('CONFIRM')
        }
      ).then(() => {
        this.$router.push('/')
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/_variables.scss';

.signup-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 0;
  overflow: hidden;
}
.logo-wrapper {
  text-align: center;
  margin-bottom: 50px;
}
.content {
  display: flex;
  flex-direction: column;
  flex: 1 0;
  overflow: hidden;
}

.el-steps {
  width: 50%;
  max-width: 480px;
  margin: 0 auto 70px;
  ::v-deep {
    .el-step__title {
      &,
      &.is-finish,
      &.is-process {
        color: $dark-black;
        font-weight: normal;
        line-height: 24px;
        margin-top: 12px;
      }
    }
    .el-step__head {
      color: white;
      border-color: $--color-info;
      .el-step__icon {
        border: none;
        width: 30px;
        height: 30px;
      }
      .el-step__line {
        height: 0;
        border-bottom: 1px dashed $text-grey;
        background-color: initial;
        top: 14.5px;
      }
      .el-step__line-inner {
        border: none;
        border-bottom: 1px dashed $--color-primary;
      }
      &.is-finish {
        color: white;
        border-color: $--color-primary;
        .el-step__icon {
          background-color: $--color-primary;
        }
      }
      .el-step__icon {
        background-color: $--color-info;
      }
    }
  }
}
</style>
