<template>
  <ResourcePage
    :query="query"
    :search-keyword="search"
    :search-keys="['name', 'email']"
  >
    <template #title>
      {{ $t('MENU_MEMBER') }}
    </template>
    <template #description>
      {{ $t('회원을 선택하시면 운동내역을 확인하실 수 있습니다.') }}
    </template>
    <template #filter>
      <el-input
        :placeholder="$t('NAME_EMAIL_SEARCHING')"
        suffix-icon="el-icon-search"
        v-model="search"
      >
      </el-input>
    </template>
    <template #list-item="scope">
      <div class="list-item">
        <span v-html="toHighlight(search, scope.row.name)" />
        <span v-html="scope.row.gender === 0 ? $t('MALE') : $t('FEMALE')" />
        <span v-html="getAge(scope.row.birth_day)" />
        <span v-html="scope.row.height + 'cm'" />
        <span v-html="scope.row.weight + 'kg'" />
      </div>
    </template>
    <template #detail>
      <MemberForm />
    </template>
  </ResourcePage>
</template>

<script>
import MemberForm from '@/views/pages/MemberForm'
import ResourcePage from '@/components/ResourcePage'
import MixinFormatUtils from '@/lib/MixinFormatUtils'
import moment from 'moment'

export default {
  name: 'MemberPage',
  mixins: [MixinFormatUtils],
  components: { ResourcePage, MemberForm },
  props: ['id', 'schema'],
  data() {
    return {
      query: {
        has_class: null
      },
      search: ''
    }
  },
  methods: {
    loadOptions() {},
    getAge(birthDay) {
      return (
        moment()
          .startOf('year')
          .diff(moment(birthDay).startOf('year'), 'years') +
        1 +
        this.$t('세')
      )
    }
  },
  mounted() {
    this.loadOptions()
  }
}
</script>

<style scoped lang="scss">
.list-item {
  display: flex;
  justify-content: space-between;

  > * {
    flex-grow: 66;
    flex-basis: 30px;
    text-align: center;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
      flex-grow: 50;
    }

    &:nth-child(3) {
      flex-grow: 100;
    }

    &:nth-child(4) {
      flex-grow: 70;
    }
  }
}
</style>
