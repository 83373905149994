<template>
  <div class="content-step-2">
    <div class="title">{{ $t('REGIST_INFO_INPUT') }}</div>
    <div class="form">
      <el-form label-width="200px" label-position="left">
        <el-form-item
          :label="`${$t('EMAIL')}(ID)`"
          :error="error && error.email"
        >
          <el-input v-model="form.email" :placeholder="$t('FIND_ID_INPUT')" />
        </el-form-item>
        <el-form-item :label="$t('NAME')" :error="error && error.name">
          <el-input v-model="form.name" :placeholder="$t('FIND_NAME_INPUT')" />
        </el-form-item>
        <el-form-item class="password" :error="error && error.password">
          <div slot="label" class="label password">
            <div>{{ $t('PASSWORD') }}</div>
            <div class="sub">({{ $t('PASSWORD_CONDITION') }})</div>
          </div>
          <el-input
            type="password"
            v-model="form.password"
            :placeholder="$t('PASSWORD_INPUT')"
          />
        </el-form-item>
        <el-form-item
          :label="$t('PASSWORD_CHECK')"
          :error="error && (error.re_password || error.non_field_errors)"
        >
          <el-input
            type="password"
            v-model="form.re_password"
            :placeholder="$t('PASSWORD_INPUT_CHECK')"
          />
        </el-form-item>
      </el-form>
    </div>
    <div class="actions">
      <el-button type="info" @click="cancel">
        {{ $t('CANCEL') }}
      </el-button>
      <el-button type="primary" @click="next">
        {{ $t('CONFIRM') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { isEmpty } from '@/lib/utils'

const MESSAGE_MAP = {
  'user with this 이메일 already exists.': '이미 등록된 이메일 입니다.',
  'This password is too short. It must contain at least 6 characters.':
    'PASSWORD_POLICY',
  'This password is entirely numeric.': 'PASSWORD_POLICY',
  "The two password fields didn't match.": 'NOT_MATCH_PASSWORD'
}
export default {
  name: 'Step2',
  props: ['shop'],
  data() {
    return {
      form: {},
      error: {}
    }
  },
  methods: {
    cancel() {
      this.$router.back()
    },
    next() {
      this.error = {}
      // eslint-disable-next-line no-useless-escape
      const regExp = /^\w+([\+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i
      if (isEmpty(this.form.email)) {
        this.error.email = this.$t('PLZ_INPUT_EMAIL')
      }
      if (this.form.email && !this.form.email.match(regExp)) {
        this.error.email = this.$t('IS_NOT_EMAIL_ADDRESS')
      }
      if (isEmpty(this.form.name)) {
        this.error.name = this.$t('FIND_NAME_INPUT')
      }
      if (isEmpty(this.form.password)) {
        this.error.password = this.$t('PASSWORD_INPUT')
      }
      if (isEmpty(this.form.re_password)) {
        this.error.re_password = this.$t('PASSWORD_INPUT_CHECK')
      }
      this.$apiServer
        .post('auth/users/', Object.assign({}, this.form, { shop: this.shop }))
        .then(() => {
          this.$emit('next', {})
        })
        .catch(error => {
          if (error.response.status == 400) {
            const data = error.response.data
            for (const k in data) {
              const msg = data[k][0]
              if (!this.error[k]) {
                this.error[k] = this.$t(MESSAGE_MAP[msg] || this.$t(msg))
              }
            }
            this.$forceUpdate()
          }
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/_variables.scss';
@import '~@/assets/scss/_mixins.scss';

.content-step-2 {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow: inherit;
  flex: 1 0;
  .form {
    border-radius: 10px;
    border: solid 1px $border-grey;
    padding: 30px;
    overflow-y: auto;
    flex: 1 0;
    .el-form {
      ::v-deep {
        label {
          font-size: 18px;
        }
      }
      .label.password {
        line-height: 24px;
        .sub {
          line-height: 16px;
          font-size: 14px;
          color: $text-grey2;
        }
      }
    }
  }
}

.title {
  font-size: 16px;
  color: $dark-black;
  margin-bottom: 10px;
}

p.error {
  font-size: 12px;
  color: $--color-danger;
}

.actions {
  @include actions;
}
</style>
