<template>
  <div class="setup-contents">
    <div class="guide-wrap">
      <div class="text-guide-title">
        {{ $t('MENU_HOME') }}
      </div>
      <div class="text-guide-content" v-html="$t('MENU_HOME_GUIDE')"></div>

      <div class="text-guide-title">
        {{ $t('MENU_HERAFIT') }}
      </div>
      <div class="text-guide-content" v-html="$t('MENU_HERAFIT_GUIDE')"></div>

      <div class="text-guide-title">
        {{ $t('MENU_TRANINER') }}
      </div>
      <div class="text-guide-content" v-html="$t('MENU_TRAINER_GUIDE')"></div>

      <div class="text-guide-title">
        {{ $t('MENU_MEMBER') }}
      </div>
      <div class="text-guide-content" v-html="$t('MENU_MEMBER_GUIDE')"></div>

      <div class="text-guide-title">
        {{ $t('MENU_CLASS') }}
        <!--        <a-->
        <!--          href="javascript:void(0);"-->
        <!--          data-toggle="modal"-->
        <!--          data-target="#guideModal02"-->
        <!--          ><span class="set_guide_btn">{{-->
        <!--            $t('DISPLAY_CONNECT_GUIDE')-->
        <!--          }}</span></a>-->
      </div>

      <div class="text-guide-content" v-html="$t('MENU_CLASS_GUIDE')"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Usage'
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/_variables.scss';

.text-guide-title {
  font-size: 18px;
  line-height: 25px;
  font-weight: bold;
  margin-bottom: 5px;
}
.text-guide-content {
  font-size: 16px;
  line-height: 25px;
  color: $text-grey2;
  margin-bottom: 45px;

  ::v-deep {
    .red {
      color: $--color-danger;
    }
  }
}
</style>
