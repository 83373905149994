<template>
  <div :is="layoutComponent">
    <router-view/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import { mapGetters } from 'vuex'
import DefaultLayout from "@/layout/DefaultLayout";
import HeadImageLayout from "@/layout/HeadImageLayout";
import BackButtonLayout from "@/layout/BackButtonLayout";
import {LAYOUT_TYPE} from "@/constants";

export default {

  name: 'app',
  computed: {
    ...mapGetters(['layout']),
    layoutComponent () {
      switch(this.layout) {
        case LAYOUT_TYPE.default:
          return 'DefaultLayout'
        case LAYOUT_TYPE.withImageHead:
          return 'HeadImageLayout'
        case LAYOUT_TYPE.withBack:
          return 'BackButtonLayout'
      }
      return 'router-view'
    }
  },
  data() {
    return {

    }
  },
  components: {
    BackButtonLayout,
    HeadImageLayout,
    DefaultLayout,
    HelloWorld
  }
}
</script>

<style lang="scss" scoped>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

::v-deep {
  .el-button {
    font-weight: normal;
  }
}
</style>
