<template>
  <div>
    <span v-if="disabled">{{ value }}</span>
    <el-input-number v-else :value="value" @input="data => $emit('input', data)" />
    &nbsp;{{ option && option.unit }}
  </div>
</template>

<script>
export default {
  name: 'SFInputNumber',
  props: ['value', 'option', 'disabled']
}
</script>

<style scoped></style>
