export default {
  "MENU_HOME": "홈",
  "MENU_HERAFIT": "헤라핏 관리",
  "MENU_TRANINER": "트레이너 관리",
  "MENU_MEMBER": "회원 관리",
  "MENU_CLASS": "클래스 관리",
  "MENU_HISTORY": "이력 관리",
  "MENU_SETUP": "설정",
  "MENU_SETUP_USER": "사용자 정보",
  "MENU_SETUP_RECEIVER": "리시버 설정",
  "MENU_SETUP_USAGE": "사용 안내",
  "MENU_SETUP_LOGO": "로고&색상 변경",
  "MENU_SETUP_APP": "앱 정보",
  "TITLE_DASHBOARD": "HERA Fit ON",
  "TITLE_SUBTITLE": "최적의 운동 효과를 위한 HERA Fit ON",
  "TITLE_CLASSMANAGE": "클래스 관리",
  "DASHBOARD_REGIST_CLASS": "등록 클래스 현황",
  "DASHBOARD_RECENT_CLASS": "최근 진행한 클래스",
  "DASHBOARD_AVG_CAL_BURN": "평균 소비 칼로리",
  "DASHBOARD_AVG_HEART_RATE": "평균 심박수",
  "DASHBOARD_TOTAL_MEMBER": "전체 회원",
  "DASHBOARD_TOTAL_TRANINER": "전체 트레이너",
  "DASHBOARD_TOTAL_HERAFIT": "전체 헤라핏",
  "DASHBOARD_TOTAL_CLASS": "전체 클래스",
  "LABEL_USER": "사용자",
  "LABEL_ID": "ID",
  "LOGIN_EMAIL": "이메일 아이디",
  "LOGIN_PW": "비밀번호",
  "LOGIN_SAVEID": "아이디 저장",
  "LOGIN_FINDINFO": "회원정보 찾기",
  "LOGIN": "로그인",
  "LOGIN_REGIST": "회원가입",
  "LOGGED_IN": "헤라핏에 로그인 되었습니다.",
  "FIND_USERINFO": "회원정보 찾기",
  "FIND_ID": "아이디 찾기",
  "FIND_PW": "비밀번호 찾기",
  "FIND_ID_GUIDE": "트레이너 정보를 입력해주세요.일치시 아이디를 확인하실 수 있습니다.<br>※관리자 정보는 아래를 통해 문의해 주세요. <a href='mailto:ceo@ehealth-one.com' class='text-underbar'>ceo@ehealth-one.com</a>",
  "FIND_ID_TYPE": "유형",
  "FIND_ID_CHECK": "아이디 확인하기",
  "FIND_PW_GUIDE": "트레이너 정보를 입력해 주세요. 일치시 새로운 비밀번호를 등록할 수 있습니다.<br>※관리자 정보는 아래를 통해 문의해 주세요. <a href='mailto:ceo@ehealth-one.com' class='text-underbar'>ceo@ehealth-one.com</a>",
  "FIND_INPUT_PW_GUIDE": "새로운 비밀번호를 입력해 주세요.",
  "FIND_ID_INPUT": "이메일 아이디를 입력하세요",
  "FIND_NAME_INPUT": '이름을 입력해 주세요.',
  "NEW_PASSWORD_REGIST": "신규 비밀번호 등록",
  "NEW_ITEM": "신규등록",
  "TRAINNER": "트레이너",
  "ADMIN": "관리자",
  "ID": "아이디",
  "EMAIL": "이메일",
  "NAME": "이름",
  "CLUB_PHONE_NUM": "클럽 전화번호",
  "CLUB_NAME": "피트니스 클럽명",
  "CLUB_LOCATION": "피트니스 클럽 위치",
  "ONLY_INPUT_NUMBER": "숫자만 입력하세요",
  "CONFIRM": "확인",
  "CANCEL": "취소",
  "PASSWORD": '비밀번호',
  "PASSWORD_CHECK": '비밀번호 확인',
  "PASSWORD_INPUT": "비밀번호를 입력하세요",
  "PASSWORD_INPUT_CHECK": "비밀번호를 한번 더 입력하세요",
  "PASSWORD_CONDITION": "영문/숫자  혼합,6자리 이상",
  "DUP_CHECK": "중복체크",
  "REGIST_STEP1_GUIDE": "관리자 등록 후 이용하실 수 있습니다.",
  "REGIST_STEP2_GUIDE": "관리자 기본 정보를 등록해 주세요.",
  "REGIST_STEP3_GUIDE": "피트니스 클럽 정보를 함께 등록해 주세요.",
  "REGIST_FINISH_GUIDE": "관리자 정보 등록이 완료되었습니다.",
  "REGIST_FINSIH_TEXT": "관리자 로그인 후 아래 절차를 진행해 주세요.",
  "REGIST_STEP1_TEXT": "약관동의",
  "REGIST_STEP2_TEXT": "기본정보 입력",
  "REGIST_STEP3_TEXT": "클럽정보 입력",
  "REGIST_AGREE_GUIDE": "서비스 이용을 위한 사용권에 동의해 주세요.",
  "REGIST_I_AGREE": "사용권 동의",
  "REGIST_I_WILL_AGREE": "약관에 동의해 주세요.",
  "REGIST_MUST_AGREE": "약관에 동의하셔야 합니다.",
  "REGIST_INFO_INPUT": "관리자 기본 정보를 입력해 주세요.",
  "REGIST_CLUB_INPUT": "피트니스 클럽 정보를 입력해 주세요.",
  "REGIST_CLUB_NAME_INPUT": "클럽명을 입력하세요.",
  "REGIST_LOCATION_INPUT": "위치를 입력하세요.",
  "REGIST_MEM": "회원 등록",
  "REGIST_CLASS": "클래스 등록",
  "REGIST_TRAINNER": "트레이너 등록",
  "MATCHING": "매칭",
  "REGIST_START_HEALTH": "운동시작",
  "SHOW_ALL": '전체보기',
  "TOTAL_CLASS": "전체 클래스",
  "SHOW_DEVICE_TYPE": "기구별 보기",
  "THREAD_MIL": "트래드밀",
  "WORKROW": "워크로",
  "CYCLE": "자전거",
  "CHALLENGE": "도전 몸짱",
  "SHOW_BY_TYPE": "유형별 보기",
  "PLS_REGIST_CLASS": "클래스를 등록해 주세요.",
  "NAME_SEARCHING": "명칭 검색",
  "NO_REGIST_CLASS": "등록된 클래스가 없습니다.",
  "CLASS_ADD": "클래스 추가",
  "AFTER_MEMBER_MATCHING": "후 회원 / 트레이너와 매칭해 주세요.",
  "REMOVE": "삭제",
  "ADD": "추가",
  "CLASS_DEVICE": "클래스 기구",
  "CLASS_TYPE": "클래스 유형",
  "CLASS_NAME": "클래스 명칭",
  "TARGET_HEART_BEAT": "목표 심박",
  "MATCHING_MEMBER": "매칭 회원",
  "LIMIT_30": "최대 30명",
  "RUNNING_TIME": "진행 시간",
  "MIN": "분",
  "MATCHING_TRAINNER": "매칭 트레이너",
  "CHOOSE_MEMBER": "회원 선택",
  "CHOOSE_TRAINNER": "트레이너 선택",
  "INPUT_CLASS_NAME": "명칭을 입력하세요",
  "CHOOSE_DEVICE": "기구 선택",
  "CHOOSE_TYPE": "유형 선택",
  "CHOOSE_HEART_BEAT": "심박 선택",
  "CHOOSE_TIME": "시간 선택",
  "INPUT_HEART_BEAT_PER": "심박 %를 입력하세요.",
  "CLASS_LIST": '클래스 목록',
  "MANAGER_TRAINNER": "담당 트레이너",
  "": "총 참여 인원",
  "HEALTH_TIME": "운동시간",
  "AVG_MAX_HEART_BEAT": "평균 최대 심박",
  "MATCHING_MEMBER_LIST": "매칭 회원 목록",
  "TOTAL_BURN_CARORIES": "총 소비 칼로리",
  "GOOD_HEART_RATE": "적정 심박수",
  "MAX_HEART_BEAT": "최대 심박",
  "HOLDING_TIME": "유지 시간",
  "HEART_BEAT_GRAPH": "심박 분포 그래프",
  "CLASS_MEMBER": "참여 인원",
  "PERSON": "명",
  "SEND_FIT_INFO": "운동 정보를 전송 합니다",
  "START": "시작",
  "YOUR_ID": "아이디는",
  "ID_IS": "입니다.",
  "ID_LOGIN_PLZ": "해당 아이디로 로그인해 주세요.",
  "ID_AND_NAME_NOT_MATCH": "<span class=\"text-orange\">아이디와 이름</span>이 일치하지 않습니다.",
  "CHECK_AND_INPUT": "확인 후 다시 입력해 주세요.",
  "HERAFIT_MANAGE": "헤라핏 관리",
  "PLS_REGIST_HERAFIT": "헤라핏을 등록해 주세요",
  "NO_REGIST_HERAFIT": "등록된 헤라핏이 없습니다.",
  "HERAFIT_ADD": "헤라핏 추가",
  "HERAFIT_MODIFY": "헤라핏 수정",
  "AND_MEMBER_MATCHING": "후 회원과 매칭해 주세요",
  "TOTAL_HERAFIT": "전체 헤라핏",
  "SERIAL_NO": "시리얼 넘버",
  "OWN_TYPE": "소유 형태",
  "HERAFIT_NAME": '헤라핏 명칭',
  "CLUB_RENTAL": "클럽 렌탈",
  "PERSONAL_OWN": "개인 소유",
  "COMPLETE": "완료",
  "INPUT_COMPLETE": "입력 완료",
  "MODIFY_COMPLETE": "수정 완료",
  "SHOW_CLUB_RENTAL": "클럽 렌탈 보기",
  "SHOW_PERSONAL_OWN": "개인 소유 보기",
  "CERTI_DATE": "인증 일자",
  "CERTI_COMPELETE": "인증 완료",
  "MODIFY": "수정",
  "TRAINNER_MANAGE": "트레이너 관리",
  "MEMBER_MANAGE": "회원 관리",
  "CLASS_MATCHING_COMP_SHOW": "클래스매칭 완료 보기",
  "CLASS_MATCHING_NOTCOMP_SHOW": "클래스매칭 미완 보기",
  "TOTAL_TRAINER": "전체 트레이너",
  "PLS_REGIST_TRAINNER": "트레이너를 등록해 주세요.",
  "NO_REGIST_TRAINNER": "등록된 트레이너가 없습니다.",
  "TRAINNER_ADD": "트레이너 등록",
  "AND_CLASS_MATCHING": "후 클래스와 매칭해 주세요",
  "NAME_EMAIL_SEARCHING": "이름 / 이메일 검색",
  "REGIST_DATE": "등록 일자",
  "GENDER": "성별",
  "HEIGHT": "키",
  "WEIGHT": "몸무게",
  "BIRTHDAY": "생년월일",
  "MATCHING_CLASS": "매칭 클래스",
  "MALE": "남",
  "FEMALE": "여",
  "PLZ_INPUT_EMAIL": "이메일을 입력하세요",
  "CHOICE": "선택",
  "PLS_REGIST_MEMBER": "회원을 등록해 주세요",
  "TOTAL_MEMBER": "전체 회원",
  "MEMBER_ADD": "회원 추가",
  "MEMBER_MODIFY": "회원 수정",
  "NO_REGIST_MEMBER": "등록된 회원이 없습니다.",
  "AFTER_HERAFIT_MATCHING": "후 헤라핏 / 클래스 와 매칭해 주세요.",
  "EMAILNOTEXIST": "사용 가능한 이메일 입니다.",
  "EMAILDUPLICATE": "이메일이 중복 됩니다.",
  "IS_NOT_EMAIL_ADDRESS": "올바른 이메일 주소가 아닙니다.",
  "PASSWORD_POLICY": "영문/숫자 혼합, 6자리 이상 입력해 주세요.",
  "NOT_MATCH_PASSWORD": "비밀번호가 일치하지 않습니다.",
  "PLZ_INPUT_CLUB_TELNO": "클럽 전화번호를 입력하세요.",
  "SAVE_FAIL": "저장 실패",
  "LOGIN_NEEDS_JOIN": "관리자 등록 후 이용하실 수 있습니다.",
  "PASSWORD_FAIL": "비밀번호가 일치하지 않습니다.",
  "NO_MANAGER_INFO": "등록된 정보가 없습니다",
  "PLZ_INPUT_BIRTHDAY": "생년월일을 입력하세요",
  "NO_ID_SEARCH": "해당 정보를 찾을수 없습니다.",
  "PASSWORD_CHANGED": "비밀번호가 변경되었습니다.",
  "FAIL": "실패",
  "PLZ_INPUT_HEIGHT": "키를 입력하세요",
  "PLZ_INPUT_WEIGHT": "몸무게를 입력하세요",
  "ARE_YOU_SURE_TRAINER_REMOVE": "선택한 트레이너를 삭제하시겠습니까?",
  "ARE_YOU_SURE_HERAFIT_REMOVE": "선택한 헤라핏을 삭제하시겠습니까?",
  "REMOVE_COMPLETE": "삭제 되었습니다.",
  "NO_SEARCH_RESULT": "검색 결과가 없습니다.",
  "WEIGHT_TRAINING": "웨이트",
  "NO_TOOL": "기구 없음",
  "LIGHT_EXERCISE": "가벼운 운동",
  "OXYGEN_EXERCISE": "유산소 운동",
  "OXYGEN_FREE_EXERCISE": "무산소 운동",
  "OXYGEN_AND_FREE_EXERCISE": "유/무산소 복합 운동",
  "DIET_EXERCISE": "다이어트 운동",
  "CALORIE_GOAL_EXERCISE": "칼로리 목표 설정 운동",
  "HEART_RATE_GOAL_EXERCISE": "심박 목표 설정 운동",
  "TIME_GOAL_EXERCISE": "시간 목표 설정 운동",
  "HERA_ZONE": "Hera Zone",
  "FREE_EXERCISE": "자율 운동",
  "PLZ_INPUT_TIME": "시간을 입력하세요",
  "WARMING_UP": "워밍업",
  "FAT_BURNING": "지방연소",
  "IMPROVE_ENDURANCE": "심폐 지구력 향상",
  "MAX_EXERCISE_EFFECT": "최대 운동 효과",
  "MAX_STRANGTH": "최대 강도",
  "MANUAL_INPUT": "직접 입력",
  "PLZ_CHOOSE_DEVICE": "클래스 기구를 선택해주세요.",
  "PLZ_CHOOSE_TYPE": "클래스 유형을 선택해주세요",
  "PLZ_CHOOSE_HEART_BEAT": "목표 심박을 설정해주세요.",
  "PLZ_CHOOSE_TIME": "진행 시간을 설정해 주세요.",
  "PLZ_CHOOSE_MEMBER": "회원을 선택해 주세요.",
  "PLZ_CHOOSE_TRAINER": "트레이너를 선택해 주세요.",
  "CHECKED_TRAINER": "선택 트레이너",
  "CHECKED_MEMBER": "선택 회원",
  "MAN_COUNT": "명",
  "CHOOSE_TRAINER_GUIDE": "클래스를 담당할 트레이너를 매칭 합니다.",
  "HEALTH_RESULT": "운동 결과",
  "ARE_YOU_SURE_CLASS_REMOVE": "선택한 클래스를 삭제하시겠습니까? 매칭된 트레이너와 회원은 자동 해제되며, 클래스 운동 기록이 전체 삭제됩니다.",
  "ARE_YOU_SURE_USER_REMOVE": "정말 회원을 삭제 하시겠습니까?",
  "CLASS_MODIFY": "클래스 수정",
  "HEART_RATE_GUIDE": "키 / 몸무게 / 생년월일 입력시 자동 산출됩니다.",
  "CHOOSE_MEMBER_GUIDE": "클래스에 참여할 회원 매칭 및 사용할 헤라핏을 지정합니다.",
  "CHOOSE_HERAFIT": "헤라핏 선택",
  "PLZ_MATCHING_MEMBER_TO_DEVICE": "회원과 헤라핏을 매칭해 주세요.",
  "SETUP": "설정",
  "ADMININFO": "사용자 정보",
  "HOWTOUSE": "사용 안내",
  "LOGO_CHANGE": "로고 변경",
  "APP_GUIDE": "앱 안내",
  "LOGOUT": "로그아웃",
  "ADMIN_MODIFY": "관리자 수정",
  "LANGUAGE_SETTING": "언어 설정",
  "PREV": "이전",

  /** DEVICE 관련 */
  "DEVICE_SN_CHECK_OK": "사용 가능한 시리얼 넘버입니다.",
  "DEVICE_SN_BLANK_ERROR": "시리얼 넘버를 입력해 주세요.",
  "DEVICE_SN_OVERLAP_ERROR": "중복된 시리얼 넘버입니다. 다른 시리얼 넘버를 입력해 주세요.",
  "DEVICE_NAME_CHECK_OK": "사용 가능한 헤라핏 명칭입니다.",
  "DEVICE_NAME_BLANK_ERROR": "헤라핏 명칭을 입력해 주세요.",      // 사용 안함
  "DEVICE_NAME_OVERLAP_ERROR": "헤라핏 명칭 중복입니다. 다시 입력해 주세요.",
  "DEVICE_DUPLICATE_MODE_ERROR": "중복 검색할 조건을 확인할 수 없습니다.",
  "DEVICE_DUPLICATE_TYPE_ERROR": "디바이스 유형을 확인할 수 없습니다.",

  /** 2018.07.09 이후 추가분 */
  "CLASS_SETTING": "클래스 설정",
  "CLASS_SETTING_GUIDE": "클래스 추가/수정시 사용할 기구와 유형을 설정합니다.",
  "TYPE_MANAGING": "유형 관리",
  "EQUIPMENT_MANAGING_BY_EXCERSISE": "기구별 운동 관리",
  "HERA_ZONE_GUIDE": "최대 심박수 84%로 12분 이상 운동 강도 유지시 다이어트 효과를 36시간 유지하는운동 유형 입니다.",
  "PLZ_INPUT_CLASS_TYPE": "유형명을 입력하세요.",
  "PLZ_INPUT_EQUIPMENT_NAME": "기구명을 입력하세요",


  "TRAINNER_MODIFY": "트레이너 수정",
  "RANKING": "위",
  "UPLOAD": "업로드",
  "CHOOSE_FILE": "파일 선택",
  "LOGO_UPLOAD_GUIDE": "- 이미지 파일 배경이 투명하지 않을 경우 흰 바탕이 생길 수 있습니다.<br> - 적정 이미지 사이즈는 가로 500px X 세로 400px 이며 일치하지 않을시 자동 변경됩니다.",
  "LOGO_GUIDE": "디스플레이 대기 중 노출되는 로고를 변경할 수 있습니다.",
  "CANNOT_USE_IMAGE_FILE": "사용할 수 없는 이미지 파일입니다. 10mb 이하 jpg, jpeg, png, bmp 이미지를 사용해 주세요.",
  "PLZ_LOGO_FILE_UPLOAD": "로고 이미지 파일을 업로드 해 주세요.",
  "ANY_QUESTION": "이용 중 문의사항이 있으신가요?",
  "MENU_HOME_GUIDE": "앱에 등록된 정보를 확인하고 관리할 수 있는 대시보드입니다.",
  "MENU_HERAFIT_GUIDE": "헤라핏을 등록하고 관리합니다. 클럽 렌탈과 개인 소유를 구분하여 관리할 수 있습니다.<br>신규로 등록할 때는 헤라핏 뒤에 기재된 고유 일련번호를 인증해 주세요.",
  "MENU_TRAINER_GUIDE": "클래스 전반을 운영하는 트레이너를 등록, 수정, 삭제할 수 있습니다.<br>트레이너 등록 후 클래스와 매칭해 주세요. 트레이너 이메일 ID와 패스워드는 트레이너 로그인 시 사용되므로 신중한 등록이 필요합니다.",
  "MENU_MEMBER_GUIDE": "클래스에 참여하는 회원을 등록합니다. 회원 신체 정보를 통해 적정 심박수가 자동 산출됩니다. 이메일 주소로 운동 정보를 전달할 수 있으니 정확하게 등록해 주세요",
  "MENU_CLASS_GUIDE": "회원과 트레이너 매칭 후 클래스를 시작할 수 있습니다. <br>디스플레이 연동 후 회원의 운동 정보를 실시간으로 확인하세요. 클래스에 사용할 기구와 유형의 자유로운 선택이 가능하며, 운동 종료 후 데이터는 자동 누적됩니다.<br><div class=\"red\">- 클래스 운동 시작 후에는 헤라핏, 트레이너, 회원, 클래스의 정보 수정이 불가능하며 열람만 가능합니다.</div>",
  "ALREADY_CLASS_EXIST": "이미 실행중인 클래스가 있습니다.",
  "PAUSE_CLASS_EXIST": "일시중지 한 클래스가 있습니다.",
  "STOP": "중단",
  "RESTART": "다시 시작",
  "FINISH": "종료",
  "PAUSE": "일시정지",

  "PLZ_CHOOSE_MEMBER_IN_CLASS": "클래스에 참여할 회원을 선택해 주세요.",
  "ARE_YOU_SURE_EXCERCISE_STOP": "클래스 운동을 종료하시겠습니까? 진행한 운동 정보로 최종 기록이 저장됩니다.",
  "USERINFO": "사용자 정보",
  "DISPLAY_CONNECT_GUIDE": "디스플레이 연동 안내",
  "PERMISSION_GUIDE": '권한 안내',
  "HERAFIT_USE_PERMISSION_GUIDE": "HERA Fit ON 이용을 위한 필수 접근 권한 안내입니다.",
  "REQUIRE_PERMISSION": "필수 접근 권한",
  "WIFI_CONNECT_INFO": "WIFI 연결 정보",
  "USE_APP_CHECK_NETWORK": "앱 이용 중 네트워크 정보를 확인합니다.",
  "BLUETOOTH_CONNECT_INFO": "블루투스 연결 정보",
  "BLUETOOTH_GUIDE": "주변 블루투스 기기로 송신하거나 연결 정보를 수집하여 제어합니다.",
  "MEDIA/FILE": "미디어 / 파일",
  "FILE_PERMISSION_GUIDE": "저장된 미디어 파일에 접근하여 업로드 합니다.",
  "CONNECT_GUIDE": '연동 안내',
  "CAN_USE_HERAFIT_CONNECT": "HERA Fit ON은 연동 후 사용 가능합니다.",
  "CHOOSE_AND_FALLOW_GUIDE": "항목 선택 후 안내를 따라 진행해 주세요.",
  "YOU_CAN_SEE_SETUP_MENU_TOO": "해당 안내는 설정 메뉴에서도 확인하실 수 있습니다.",
  "DISPLAY_SHARE_CONNECT_APP": "클래스 진행시 운동 정보를 안내하는 대형 디스플레이와 앱을 연결합니다.",
  "DISPLAY_AND_SERVER": "디스플레이 - 서버 연동 안내",
  "HOWTO_SERVER_CONNECT": "서버를 통한 연동입니다. 아래 안내를 따라 진행해 주세요.",
  "SERVER_TURN_ON": "서버의 전원을 켭니다.",
  "TV_INPUT_TYPE_SET": "TV 입력 방식을 설정합니다.",
  "INPUT_OR_SETUPBOX": "외부입력 혹은 세탑박스",
  "TV_READY_CONNECT_SUCCESS": "TV 상태가 ready로 변경되면<br/>연동 성공입니다.",
  "DISPLAY_APP_CONNECT": "디스플레이 - 앱 연동",
  "MIRRORING_CONNECT": "미러링 연결",
  "CONNECT_COMPLETE": "연동 완료",
  "MAYBE_LATER": "나중에 진행하기",
  "NEXT": "다음",
  "DISPLAY_MIRRORING_CONNECT_GUIDE": "디스플레이 - 미러링 연동 안내",
  "IF_HARD_TO_CONNECT_FALLOW_THIS_GUIDE": "서버 연동이 어려울 시 아래 안내를 따라 미러링 세팅을 진행해 주세요.",
  "INPUT_TV_DONGLE": "TV에 동글을 삽입합니다.",
  "TURN_ON_DONGLE": "동글의 전원을 연결합니다.",
  "TURN_ON_TV_AND_INPUT_TYPE_CHOOSE": "TV의 전원을 키고 외부입력<br/>(HDMI)를 선택합니다.",
  "TV_SCREEN_CHOOSE_MIRACAST": "TV 화면에서 Miracast를<br/>선택하세요.",
  "TABLET_WIRELESS_DISPLAY_SET_ON": "태블릿 설정에서 무선 디스플레이를<br/>on으로 변경 후 Miracast 장치가 검색될 때까지<br/>대기하세요.",
  "INPUT_PIN_ON_TV": "PIN코드 입력 필요시,<br/>TV에 표시된 PIN 코드를 태블릿에 입력해주세요.",
  "TV_SCREEN_ON_TABLET_SUCCESS": "TV 화면에 태블릿 화면이 표시되면<br/>연동 성공입니다.",
  "QUESTION_TO_ADMIN": "관리자 문의",
  "IF_DO_NOT_CONNECT_CANNOT_RUNNINGCLASS_ARE_YOU_SURE": "디스플레이 미연동시 클래스를 진행하실 수 없습니다. 나중에 진행하시겠습니까?",

  "RESULT_CHECK": "기록 확인",
  "CLASS_TYPE_DUPLICATE": "클래스 유형 명 이 중복됩니다. 다시확인해주세요.",
  "CLASS_TYPE_REMOVE_MSG": "클래스 유형을 삭제하시겠습니까?",
  "CLASS_TYPE_REMOVE_MSG2": "기존에 적용된 데이터는 유지됩니다.",
  "CLASS_TYPE_MODIFY_MSG": "클래스 유형명을 수정하시겠습니까?",
  "CLASS_TYPE_MODIFY_MSG2": "수정된 데이터로 전체 적용 됩니다.",
  "HEALTHONE_ADDR": "경기도 고양시 일산서구 산남로 132 (구산동 265-2)",

  "HERAFIT_BUY_POPUP_IMG": "img_band.png",
  "HERAFIT_BTN_BUY": "btn_buy.png",

  "NAME_AND_BIRTHDAY_MISMATCH": "이름과 생년월일이 일치하지 않습니다. 확인 후 다시 입력해 주세요.",
  "SEND_EMAIL": "이메일 전송",
}
