export default {
  "MENU_HOME": "ホーム",
"MENU_HERAFIT": "HERA Fit+の管理",
"MENU_TRANINER": "トレーナーの管理",
"MENU_MEMBER": "会員の管理",
"MENU_CLASS": "グループの管理",
"MENU_HISTORY": "History",
"MENU_SETUP": "設定",
"MENU_SETUP_USER": "사용자 정보",
"MENU_SETUP_RECEIVER": "리시버 설정",
"MENU_SETUP_USAGE": "사용 안내",
"MENU_SETUP_LOGO": "로고 변경",
"MENU_SETUP_APP": "앱 정보",
"TITLE_DASHBOARD": "HERA Fit ON",
"TITLE_SUBTITLE": "運動管理システムのHERA Fit　ON",
"TITLE_CLASSMANAGE": "グループの管理",
"DASHBOARD_REGIST_CLASS": "登録グループ一覧",
"DASHBOARD_RECENT_CLASS": "最近実施したグループ",
"DASHBOARD_AVG_CAL_BURN": "平均消費カロリー",
"DASHBOARD_AVG_HEART_RATE": "平均心拍数",
"DASHBOARD_TOTAL_MEMBER": "全会員",
"DASHBOARD_TOTAL_TRANINER": "トレーナー",
"DASHBOARD_TOTAL_HERAFIT": "登録済みHERA Fit+一覧",
"LOGIN_EMAIL": "メールアドレス（ID）",
"LOGIN_PW": "パスワード",
"LOGIN_SAVEID": "入力情報の保存",
"LOGIN_FINDINFO": "会員情報の検索",
"LOGIN": "ログイン",
"LOGIN_REGIST": "管理者登録",
"FIND_USERINFO": "会員情報の検索",
"FIND_ID": "メールアドレス（ID）検索",
"FIND_PW": "パスワードの検索",
"FIND_ID_GUIDE": "トレーナー情報を入力してください。<br/>＊管理者情報はメールで問い合わせください。",
"FIND_ID_TYPE": "タイプの",
"FIND_ID_CHECK": "メールアドレス（ID）の確認",
"FIND_PW_GUIDE": "トレーナー情報を入力してください。<br/>＊管理者情報はメールで問い合わせください。",
"FIND_INPUT_PW_GUIDE": "新しいパスワードを入力してください。",
"FIND_ID_INPUT": "メールアドレス（ID）を入力してください。",
"FIND_NAME_INPUT": "名前を入力してください。",
"NEW_PASSWORD_REGIST": "パスワードの再設定",
"TRAINNER": "トレーナー",
"ADMIN": "管理者",
"ID": "ID",
"EMAIL": "メールアドレス",
"NAME": "名前",
"CLUB_PHONE_NUM": "クラブの電話番号",
"CLUB_NAME": "フィットネスクラブ名",
"CLUB_LOCATION": "フィットネスクラブの住所",
"ONLY_INPUT_NUMBER": "数字のみを入力してください",
"CONFIRM": "OK",
"CANCEL": "キャンセル",
"PASSWORD": "パスワード",
"PASSWORD_CHECK": "パスワードの確認",
"PASSWORD_INPUT": "パスワードを入力してください",
"PASSWORD_INPUT_CHECK": "パスワードをもう一度入力してください",
"PASSWORD_CONDITION": "英数字を含む、6桁以上",
"DUP_CHECK": "重複チェック",
"REGIST_STEP1_GUIDE": "管理者のアカウントを作成後利用することができます。",
"REGIST_STEP2_GUIDE": "管理者の基本情報を登録してください。",
"REGIST_STEP3_GUIDE": "フィットネスクラブの情報を登録してください。",
"REGIST_FINISH_GUIDE": "管理者情報の登録が完了しました。",
"REGIST_FINSIH_TEXT": "管理者のログイン後、以下の手順を進めてください。",
"REGIST_STEP1_TEXT": "利用規約に同意",
"REGIST_STEP2_TEXT": "基本情報の入力",
"REGIST_STEP3_TEXT": "クラブ情報の入力",
"REGIST_AGREE_GUIDE": "サービス利用のため使用条件に同意してください。",
"REGIST_I_AGREE": "使用条件同意",
"REGIST_I_WILL_AGREE": "利用規約に同意してください。",
"REGIST_MUST_AGREE": "約款に同意してください。",
"REGIST_INFO_INPUT": "管理者の基本情報を登録してください。",
"REGIST_CLUB_INPUT": "フィットネスクラブの情報を登録してください。",
"REGIST_CLUB_NAME_INPUT": "クラブ名を入力してください",
"REGIST_LOCATION_INPUT": "住所を入力してください",
"REGIST_MEM": "会員登録",
"REGIST_CLASS": "クラスの登録",
"REGIST_TRAINNER": "トレーナー登録",
"MATCHING": "マッチング",
"REGIST_START_HEALTH": "運動開始",
"SHOW_ALL": "一覧",
"TOTAL_CLASS": "グループ一覧",
"SHOW_DEVICE_TYPE": "マシン別の表示",
"THREAD_MIL": "トレッドミル",
"WORKROW": "WalkRo",
"CYCLE": "バイク",
"CHALLENGE": "도전 몸짱", //일어 번역 없음.
"SHOW_BY_TYPE": "タイプ別表示",
"PLS_REGIST_CLASS": "グループを登録してください。",
"NAME_SEARCHING": "名称検索",
"NO_REGIST_CLASS": "登録されたグループがありません。",
"CLASS_ADD": "グループの追加",
"AFTER_MEMBER_MATCHING": "後の会員/トレーナーの登録をしてください。",
"REMOVE": "削除",
"ADD": "追加",
"CLASS_DEVICE": "グループ利用マシン",
"CLASS_TYPE": "グループタイプ",
"CLASS_NAME": "グループ名",
"TARGET_HEART_BEAT": "目標心拍",
"MATCHING_MEMBER": "グループメンバー",
"LIMIT_30": "最大30人",
"RUNNING_TIME": "時間",
"MIN": "分",
"MATCHING_TRAINNER": "マッチングトレーナー",
"CHOOSE_MEMBER": "会員の選択",
"CHOOSE_TRAINNER": "トレーナーの選択",
"INPUT_CLASS_NAME": "名称を入力してください",
"CHOOSE_DEVICE": "マシンの選択",
"CHOOSE_TYPE": "グループタイプを選択",
"CHOOSE_HEART_BEAT": "心拍選択",
"CHOOSE_TIME": "時間の選択",
"INPUT_HEART_BEAT_PER": "心拍％を入力してください",
//"CLASS_LIST": "'クラスリスト',
"MANAGER_TRAINNER": "マッチングトレーナー",
"TOTAL_CLASS_MEMBER": "総参加人数",
"HEALTH_TIME": "運動時間",
"AVG_MAX_HEART_BEAT": "平均最大心拍数",
"MATCHING_MEMBER_LIST": "マッチングメンバーリスト",
"TOTAL_BURN_CARORIES": "総消費カロリー",
"GOOD_HEART_RATE": "適正心拍数",
"MAX_HEART_BEAT": "最大心拍数",
"HOLDING_TIME": "維持時間",
"HEART_BEAT_GRAPH": "心拍数変動グラフ",
"CLASS_MEMBER": "参加人数",
"PERSON": "人",
"SEND_FIT_INFO": "運動情報を発送します。",
"START": "開始",
"YOUR_ID": "IDは",
"ID_IS": "です。",
"ID_LOGIN_PLZ": "このIDでログインしてください。",
"ID_AND_NAME_NOT_MATCH": "<span class=\"text-orange\">IDと名前</span>が正しくありません。確認後再度入力してください。",
"CHECK_AND_INPUT": "確認後再度入力してください。",
"HERAFIT_MANAGE": "HERA Fit+の管理",
"PLS_REGIST_HERAFIT": "HERA Fit+を登録してください。",
"NO_REGIST_HERAFIT": "登録されたHERA Fit+がありません",
"HERAFIT_ADD": "HERA Fit+の追加",
"HERAFIT_MODIFY": "HERA Fit+の修正",
"AND_MEMBER_MATCHING": "後、会員情報に登録をしてください。",
"TOTAL_HERAFIT": "登録済みHERA Fit+一覧",
"SERIAL_NO": "シリアルナンバー",
"OWN_TYPE": "登録形態",
"HERAFIT_NAME": "HERA Fit+の名称",
"CLUB_RENTAL": "レンタル",
"PERSONAL_OWN": "個人登録",
"COMPLETE": "完了",
"INPUT_COMPLETE": "入力 完了",
"MODIFY_COMPLETE": "修正 完了",
"SHOW_CLUB_RENTAL": "HERA Fit＋レンタル一覧",
"SHOW_PERSONAL_OWN": "HERA Fit+個人登録一覧",
"CERTI_DATE": "登録日",
"CERTI_COMPELETE": "登録完了",
"MODIFY": "修正",
"TRAINNER_MANAGE": "トレーナーの管理",
"MEMBER_MANAGE": "会員の管理",
"CLASS_MATCHING_COMP_SHOW": "グループ登録完了",
"CLASS_MATCHING_NOTCOMP_SHOW": "グループ登録情報を確認する",
"TOTAL_TRAINER": "トレーナー一覧",
"PLS_REGIST_TRAINNER": "トレーナーを登録してください",
"NO_REGIST_TRAINNER": "登録されたトレーナーがありません。",
"TRAINNER_ADD": "トレーナーを追加した",
"AND_CLASS_MATCHING": "後、グループと登録をしてください。",
"NAME_EMAIL_SEARCHING": "名前/メールアドレス検索",
"REGIST_DATE": "登録日",
"GENDER": "性別",
"HEIGHT": "身長",
"WEIGHT": "体重",
"BIRTHDAY": "生年月日",
"MATCHING_CLASS": "選択グループ",
"MALE": "男性",
"FEMALE": "女性",
"PLZ_INPUT_EMAIL": "メールアドレスを入力してください",
"CHOICE": "選択",
"PLS_REGIST_MEMBER": "会員登録をしてください。",
"TOTAL_MEMBER": "全会員",
"MEMBER_ADD": "会員追加",
"MEMBER_MODIFY": "グループ参加者の修正",
"NO_REGIST_MEMBER": "登録された会員情報がありません。.",
"AFTER_HERAFIT_MATCHING": "会員情報を追加後にHERA Fit+とグループをマッチングしてください。.",
"EMAILNOTEXIST": "使用可能なメールアドレスです。",
"EMAILDUPLICATE": "重複したメールアドレスです。",
"IS_NOT_EMAIL_ADDRESS": "メールアドレスが正しくありません。",
"PASSWORD_POLICY": "英数字を含む、6桁の以上のパスワードを入力してください。",
"NOT_MATCH_PASSWORD": "パスワードが一致しません。",
"PLZ_INPUT_CLUB_TELNO": "クラブの電話番号を入力してください",
//"SAVE_FAIL": ""저장 실패", // 일어 번역 없음.
"LOGIN_NEEDS_JOIN": "管理者のアカウントを作成後利用することができます。",
"PASSWORD_FAIL": "パスワードが一致しません。",
"NO_MANAGER_INFO": "관리자 정보가 존재 하지 않습니다", // 일어 번역 없음.
"PLZ_INPUT_BIRTHDAY": "生年月日を入力してください。",
"NO_ID_SEARCH": "해당 정보를 찾을수 없습니다.", // 일어 번역 없음.
"PASSWORD_CHANGED": "비밀번호가 변경되었습니다.", // 일어 번역 없음.
"FAIL": "失敗",
"PLZ_INPUT_HEIGHT": "身長を入力してください。",
"PLZ_INPUT_WEIGHT": "体重を入力してください。",
"ARE_YOU_SURE_TRAINER_REMOVE": "選択されたトレーナーを削除しますか？",
"ARE_YOU_SURE_HERAFIT_REMOVE": "選択されたHERA Fit+を削除しますか？",
"REMOVE_COMPLETE": "削除",
"NO_SEARCH_RESULT": "検索の結果が該当がありません。",
"WEIGHT_TRAINING": "ウェイト",
"NO_TOOL": "マシンの選択なし",
"LIGHT_EXERCISE": "軽い運動",
"OXYGEN_EXERCISE": "有酸素運動",
"OXYGEN_FREE_EXERCISE": "無酸素運動",
"OXYGEN_AND_FREE_EXERCISE": "有/無酸素複合運動",
"DIET_EXERCISE": "ダイエット運動",
"CALORIE_GOAL_EXERCISE": "目標カロリー設定運動",
"HEART_RATE_GOAL_EXERCISE": "目標心拍設定運動",
"TIME_GOAL_EXERCISE": "目標時間設定運動",
"HERA_ZONE": "Hera Zone",
"FREE_EXERCISE": "個人運動",
"PLZ_INPUT_TIME": "時間を入力してください",
"WARMING_UP": "ウォームアップ",
"FAT_BURNING": "脂肪燃焼",
"IMPROVE_ENDURANCE": "心肺持久力の向上",
"MAX_EXERCISE_EFFECT": "最大運動効果",
"MAX_STRANGTH": "最大強度",
"MANUAL_INPUT": "直接入力",
"PLZ_CHOOSE_DEVICE": "グループ利用マシンを選択してください。",
"PLZ_CHOOSE_TYPE": "グループタイプを選択してください。",
"PLZ_CHOOSE_HEART_BEAT": "目標心拍％を入力してください。",
"PLZ_CHOOSE_TIME": "時間を入力してください。",
"PLZ_CHOOSE_MEMBER": "会員を選択してください。",
"PLZ_CHOOSE_TRAINER": "トレーナーを選んでください。",
"CHECKED_TRAINER": "選択トレーナー",
"CHECKED_MEMBER": "選択会員",
"MAN_COUNT": "人",
"CHOOSE_TRAINER_GUIDE": "グループを担当するトレーナーをマッチングします。",
"HEALTH_RESULT": "運動結果",
"ARE_YOU_SURE_CLASS_REMOVE": "選択したグループを削除しますか？マッチングされたトレーナーと会員は自動的に解除され、グループ運動履歴がすべて削除されます。",
"ARE_YOU_SURE_USER_REMOVE": "会員情報を削除しますか？",
"CLASS_MODIFY": "グループの修正",
"HEART_RATE_GUIDE": "身長/体重/生年月日の入力時に自動算出されます。",
"CHOOSE_MEMBER_GUIDE": "グループに参加する会員のマッチングと使用するHERA Fit+を指定します。",
"CHOOSE_HERAFIT": "HERA Fit+選択",
"PLZ_MATCHING_MEMBER_TO_DEVICE": "会員とHERA Fit+をマッチングしてください。",
"SETUP": "設定",
"ADMININFO": "ユーザー情報",
"HOWTOUSE": "使用案内",
"LOGO_CHANGE": "ロゴの変更",
"APP_GUIDE": "アプリのご案内",
"LOGOUT": "ログアウト",
"ADMIN_MODIFY": "管理者修正",
"LANGUAGE_SETTING": "言語設定",
"PREV": "前に戻る",

/** DEVICE 관련 */
"DEVICE_SN_CHECK_OK": "使用可能なシリアルナンバーです。",
"DEVICE_SN_BLANK_ERROR": "シリアルナンバーを入力してください。",
"DEVICE_SN_OVERLAP_ERROR": "重複しているシリアルナンバーです。他のシリアルナンバーを入力してください。",
"DEVICE_NAME_CHECK_OK": "使用可能なHERA Fit+の名称です。",
"DEVICE_NAME_BLANK_ERROR": "HERA Fit+の名称を入力してください。",      // 사용 안함
"DEVICE_NAME_OVERLAP_ERROR": "HERA Fit+の名称が重複しています。別の名称を再入力してください。",
"DEVICE_DUPLICATE_MODE_ERROR": "検索条件が確認できません。",
"DEVICE_DUPLICATE_TYPE_ERROR": "連動可能なデバイスが確認できません。",

/** 2018.07.09 이후 추가분 */
"CLASS_SETTING": "グループ設定",
"CLASS_SETTING_GUIDE": "グループの追加/修正時に使用するマシンや種類を設定します。",
"TYPE_MANAGING": "タイプの管理",
"EQUIPMENT_MANAGING_BY_EXCERSISE": "マシン別の運動管理",
"HERA_ZONE_GUIDE": "最大心拍数の84％範囲で12分以上続けるとダイエット効果が36時間維持できる運動です。",
"PLZ_INPUT_CLASS_TYPE": "クラス種類を入力してください。",
"PLZ_INPUT_EQUIPMENT_NAME": "マシンを入力してください",

"TRAINNER_MODIFY": "トレーナー修正",
"RANKING": "位",
"UPLOAD": "アップロード",
"CHOOSE_FILE": "ファイルの選択",
"LOGO_UPLOAD_GUIDE": "- 画像ファイルの背景が透明でない場合白地が生じることがあります。<br>- 適正画像サイズは横500pxx縦400pxで一致していない時に自動的に変更されます。",
"LOGO_GUIDE": "ディスプレイに表示されるロゴを変更することができます。",
"CANNOT_USE_IMAGE_FILE": "使用できない画像ファイルです。10mb以下のjpg、jpeg、png、bmp画像を使用してください。",
"PLZ_LOGO_FILE_UPLOAD": "ロゴイメージファイルをアップロードしてください。",
"ANY_QUESTION": "ご利用中のお問い合わせはありますか？",
"MENU_HOME_GUIDE": "アプリに登録された情報を確認し、管理することができるダッシュボードです。",
"MENU_HERAFIT_GUIDE": "HERA Fit+を登録して管理します。クラブのレンタル品と個人所有品を区分して管理することができます。<br>新規に登録する場合は、HERA Fit + の後ろに記載された固有のシリアル番号を認証してください。",
"MENU_TRAINER_GUIDE": "グループ全体を運営するトレーナーを登録、変更、削除することができます。<br>トレーナーメールアドレス（ID）とパスワードは、トレーナーのログイン時に使用するため登録が必要です。",
"MENU_MEMBER_GUIDE": "グループに参加する会員を登録します。会員の身体情報を使用して適正心拍数が自動的に算出されます。メールアドレスに運動情報を送るため確認後登録してください。",
"MENU_CLASS_GUIDE": "会員とトレーナーマッチング後のグループを開始することができます。<br>ディスプレイ連動後の会員の運動情報をリアルタイムで確認してください。グループに使用するマシンと詳細の自由な選択が可能で、運動終了後のデータは、自動的に累積されます。<br><div class=\"red\">- グループの運動開始後は、HERA Fit+、トレーナー、会員は、グループの情報修正が不可能であり閲覧のみ可能です。</div>",
"ALREADY_CLASS_EXIST": "既に進行中のクラスがあります。",
"PAUSE_CLASS_EXIST": "一時停止状態のクラスがあります。",
"STOP": "中断",
"RESTART": "再起動",
"FINISH": "終了",

"PLZ_CHOOSE_MEMBER_IN_CLASS": "グループに参加する会員を選択してください。",
"ARE_YOU_SURE_EXCERCISE_STOP": "グループの運動を終了しますか？運動情報は記録が保存されます。",
"USERINFO": "ユーザー情報",
"DISPLAY_CONNECT_GUIDE": "ディスプレイ接続案内",
"PERMISSION_GUIDE": "権限範囲の案内",
"HERAFIT_USE_PERMISSION_GUIDE": "HERA Fit ON利用のために必要なアクセス権のご案内です。",
"REQUIRE_PERMISSION": "必須項目",
"WIFI_CONNECT_INFO": "Wi-Fi接続情報",
"USE_APP_CHECK_NETWORK": "アプリ利用中のネットワーク情報を確認します。",
"BLUETOOTH_CONNECT_INFO": "Bluetooth®接続情報",
"BLUETOOTH_GUIDE": "周辺のBluetooth®機器に送信をしたり、接続を操作します。",
"MEDIA": "イメージファイル",
"FILE_PERMISSION_GUIDE": "保存されたイメージファイルをアップロードします。",
"CONNECT_GUIDE": "接続方法",
"CAN_USE_HERAFIT_CONNECT": "ディスプレイと接続することで使用可能になります。",
"CHOOSE_AND_FALLOW_GUIDE": "項目を選択し、案内に従って操作してください。",
"YOU_CAN_SEE_SETUP_MENU_TOO": "この案内は、設定メニューからも確認することができます。",
"DISPLAY_SHARE_CONNECT_APP": "グループ運動時の情報を表示するディスプレイとアプリを接続します。",
"DISPLAY_AND_SERVER": "ディスプレイ - サーバ接続方法",
"HOWTO_SERVER_CONNECT": "サーバーを通じて接続します。次の指示に従って設定を行ってください。",
"SERVER_TURN_ON": "サーバーの電源を入れます。",
"TV_INPUT_TYPE_SET": "外部入力の設定をします。",
"INPUT_OR_SETUPBOX": "外部入力若しくはテレビチューナー",
"TV_READY_CONNECT_SUCCESS": "ディスプレイにreadyが表示された場合接続完了です。",
"DISPLAY_APP_CONNECT": "ディスプレイ - アプリ連動",
"MIRRORING_CONNECT": "ミラーリングの接続をします。",
"CONNECT_COMPLETE": "接続完了",
"MAYBE_LATER": "スキップ",
"NEXT": "次へ",
"DISPLAY_MIRRORING_CONNECT_GUIDE": "ディスプレイ - ミラーリングの接続方法",
"IF_HARD_TO_CONNECT_FALLOW_THIS_GUIDE": "サーバーの接続ができない場合、以下の指示に従って、ミラーリングの設定を行ってください。",
"INPUT_TV_DONGLE": "ディスプレイにHDMIドングルを挿入します。",
"TURN_ON_DONGLE": "ドングルを接続します。",
"TURN_ON_TV_AND_INPUT_TYPE_CHOOSE": "ディスプレイの電源をオンにし、外部入力（HDMI）を選択します。",
"TV_SCREEN_CHOOSE_MIRACAST": "ディスプレイからMiracastを選択します。",
"TABLET_WIRELESS_DISPLAY_SET_ON": "タブレットの設定でワイヤレスディスプレイをonに変更した後Miracastデバイスが検出されるまで待機します。",
"INPUT_PIN_ON_TV": "ディスプレイに表示されたPINコードをタブレットに入力してください。",
"TV_SCREEN_ON_TABLET_SUCCESS": "ディスプレイにタブレットの画面が表示されたら接続完了です。",
"QUESTION_TO_ADMIN": "お問い合わせ",
"IF_DO_NOT_CONNECT_CANNOT_RUNNINGCLASS_ARE_YOU_SURE": "ディスプレイと接続しないとグループ運動ができません。スキップしますか？",

"RESULT_CHECK": "記録を確認",
"CLASS_TYPE_DUPLICATE": "클래스 유형 명 이 중복됩니다. 다시확인해주세요.", //일어 번역 없음
"CLASS_TYPE_REMOVE_MSG": "グループの詳細を削除しますか？",
"CLASS_TYPE_REMOVE_MSG2": "既存の適用されたデータは保持されます。",
"CLASS_TYPE_MODIFY_MSG": "グループの詳細を変更しますか？",
"CLASS_TYPE_MODIFY_MSG2": "変更されたデータ全体に適用されます。",
"HEALTHONE_ADDR": "132,Sannam-ro,Ilsanseo-gu,Goyang-si,Gyeonggi-do,Rep.of Korea",

"HERAFIT_BUY_POPUP_IMG": "img_band_jp.png",
"HERAFIT_BTN_BUY": "btn_buy_jp.png",

"NAME_AND_BIRTHDAY_MISMATCH": "名前と生年月日が正しくありません。確認後再度入力してください。",
"SEND_EMAIL": "メール発送",
}
