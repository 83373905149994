<template>
  <div>
    <span v-if="disabled">{{ valueText }}</span>
    <el-select
      v-else-if="optionList"
      :value="value"
      multiple
      filterable
      collapse-tags
      :placeholder="$t('선택해주세요')"
      @input="data => $emit('input', data)"
    >
      <el-option
        v-for="(v, k) in optionList"
        :label="option.displayText ? option.displayText(v) : v.name || v"
        :value="v.id || k"
        :key="v.id || k"
      />
    </el-select>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SFMultiSelect',
  props: ['value', 'option', 'disabled'],
  computed: {
    ...mapGetters(['resources']),
    optionList() {
      if (typeof this.option.resource === 'string') {
        return this.resources[this.option.resource]
      } else {
        return this.option
      }
    },
    valueText() {
      return (
        this.optionList &&
        this.optionList
          .filter(i => this.value.includes(i.id))
          .map(i =>
            this.option.displayText ? this.option.displayText(i) : i.name
          )
          .join(', ')
      )
    }
  },
  watch: {
    resources() {
      this.$forceUpdate()
    }
  },
  methods: {
    ...mapActions(['loadResource'])
  },
  mounted() {
    if (typeof this.option.resource === 'string') {
      this.loadResource(this.option.resource)
    }
  }
}
</script>

<style scoped></style>
