<template>
  <div class="report-wrap" v-if="detail">
    <div class="round-box header">
      <div class="class-info" v-if="showTitle">
        <div class="info-wrap">
          <div class="name">{{ detail.name }}</div>
          <div class="started-at">{{ toStartedTime(detail.started_at) }}</div>
          <div class="class-name">{{ detail.class_name }}</div>
        </div>
      </div>
      <div class="workout-info">
        <div class="info-wrap">
          <div class="info-item">
            <div class="value">
              <span class="icon running-time" /><span>{{
                toRunningTime_3(detail.running_time)
              }}</span>
            </div>
            <div class="unit">Exercise Time</div>
          </div>
          <div class="info-item">
            <div class="value">
              <span class="icon heart" /><span>{{
                Math.round(detail.avg_hr)
              }}</span>
            </div>
            <div class="unit">Avg Rate (bpm)</div>
          </div>
          <div class="info-item">
            <div class="value">
              <span class="icon heart" /><span>{{ detail.max_hr }}</span>
            </div>
            <div class="unit">Max Rate (bpm)</div>
          </div>
          <div class="info-item">
            <div class="value">
              <span class="icon calories" /><span>{{ Math.round((detail.calories/3)+6) }}</span>
            </div>
            <div class="unit">Calorie (kcal)</div>
          </div>
        </div>
      </div>
    </div>
    <div class="tow-column">
      <div>
        <div class="round-box chart-wrap">
          <div class="title">HR Data Statistics</div>
          <div class="cahrt-progress">
            <table>
              <tbody>
                <tr v-for="(r, i) in ranges" :key="i">
                  <td class="range" width="80px">{{ r.range }}</td>
                  <td class="data">
                    <el-progress
                      :percentage="
                        Math.round(
                          (detail[`range${i + 1}_time`] / detail.running_time) *
                            100
                        )
                      "
                      :color="r.color"
                    ></el-progress>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div>
        <div class="round-box chart-wrap">
          <div class="title">Pie Graph for Heart Rate</div>
          <div class="pie-chart">
            <DoughnutChart
              :data="chartData"
              :height="160"
              :width="0"
              :key="`${detail.workout_class_execution}-${detail.member}`"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="round-box chart-wrap">
      <div class="title-wrap">
        <div class="title">HR Data Statistics</div>
        <div class="end-time-wrap">
          <div>
            <a class="label">회복률:</a>
            <a class="value">{{ recoverRate }}%</a>
          </div>
          <div>
            <a class="label sub">직접입력 :</a>
            <a class="value"
              ><el-time-picker
                @change="updateRecoverRate"
                size="small"
                v-model="endTime"
                :picker-options="{
                  selectableRange: `00:00:00 - ${toRunningTime_3(
                    detail.running_time
                  )}`
                }"
                placeholder="회복시점"
              ></el-time-picker
            ></a>
          </div>
        </div>
      </div>
      <HrChart
        ref="chart"
        v-if="detail.hrs"
        :max-hr="maxHr"
        :chart-data="{ data: detail.hrs }"
        :height="160"
        :width="0"
        :colorMap="colorMap"
        :on-click="onChartClick"
      />
      <div v-else style="height: 180px"></div>
    </div>
  </div>
</template>

<script>
import MixinFormatUtils from '@/lib/MixinFormatUtils'
import DoughnutChart from '@/components/chart/DoughnutChart'
import HrChart from '@/components/chart/HrChart'
import { mapGetters } from 'vuex'
export default {
  name: 'Report',
  components: { HrChart, DoughnutChart },
  mixins: [MixinFormatUtils],
  props: {
    detail: null,
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    endTime() {
      this.updateRecoverRate()
    }
  },
  computed: {
    ...mapGetters(['user']),
    chartData() {
      return {
        label: this.ranges.map(i => i.range),
        color: this.ranges.map(i => i.color),
        time: this.ranges.map((_, i) => this.detail[`range${i + 1}_time`])
      }
    },
    maxHr() {
      return (
        this.detail &&
        (this.detail.member.max_hr ||
          this.getMaxHr(this.detail.member.birth_day))
      )
    },
    restHr() {
      return (
          this.detail && this.detail.member.optimal_hr
      )
    }
  },
  data() {
    return {
      recoverRate: 0,
      endTime: new Date(1, 1, 1, 0, 0),
      ranges: [
        {
          range: '0 ~ 59%',
          color: (this.user && this.user.shop.level1_color) || '#949494'
        },
        {
          range: '60 ~ 69%',
          color: (this.user && this.user.shop.level2_color) || '#7ab9f2'
        },
        {
          range: '70 ~ 83%',
          color: (this.user && this.user.shop.level3_color) || '#90c56a'
        },
        {
          range: '84 ~ 91%',
          color: (this.user && this.user.shop.level4_color) || '#f49537'
        },
        {
          range: '92 ~ 100%',
          color: (this.user && this.user.shop.level5_color) || '#bf2132'
        }
      ],
    colorMap: null
    }
  },
  methods: {
    updateRecoverRate() {
      const t = new Date()
      const recoverAt = (this.endTime - t.getTimezoneOffset()*60*1000) % (24 * 60 * 60 * 1000)
      let hr = 0
      const hrs = this.detail.hrs
      const baseTime = Date.parse(hrs.time[0])
      for (let i = 0; i < hrs.time.length; i++) {
        const t = Date.parse(hrs.time[i]) - baseTime
        hr = hrs.hr[i]
        // eslint-disable-next-line no-console
        if (t >= recoverAt + (2 * 60 * 1000)) {
          break
        }
      }
      // eslint-disable-next-line no-console
      console.log(hr)
      this.recoverRate = Math.round((this.maxHr - hr) / (this.maxHr - this.restHr) * 100)
    },
    onChartClick(e) {
      if (!(this.$refs.chart && this.$refs.chart._data)) {
        return
      }
      const activePoint = this.$refs.chart._data._chart.getElementAtEvent(e)[0]
      console.log("activePoint:"+activePoint)
      const data = activePoint._chart.data
      const datasetIndex = activePoint._datasetIndex
      const value = data.datasets[datasetIndex].data[activePoint._index]
      // eslint-disable-next-line no-console
      const t = new Date(value.x)
      this.endTime = t.getTime()+(t.getTimezoneOffset()*60*1000)
    }
  },
  mounted() {

    this.ranges = [
        { range: '0 ~ 59%', color: this.user.shop.level1_color },
        { range: '60 ~ 69%', color: this.user.shop.level2_color },
        { range: '70 ~ 83%', color: this.user.shop.level3_color },
        { range: '84 ~ 91%', color: this.user.shop.level4_color },
        { range: '92 ~ 100%', color: this.user.shop.level5_color }
      ]
    this.colorMap = [this.user.shop.level1_color, this.user.shop.level2_color, this.user.shop.level3_color, this.user.shop.level4_color, this.user.shop.level5_color]
    
    // console.log("this.colorMap:"+this.colorMap)
  }
}
</script>

<style scoped lang="scss">
.report-wrap {
  display: flex;
  flex-direction: column;
}
.round-box {
  padding: 20px 30px;
  border-radius: 20px;
  background-color: #ffffff;
  margin-bottom: 20px;
  font-size: 20px;
}
.tow-column {
  display: flex;
  flex-direction: column;
  @media (min-width: 1600px) {
    flex-direction: row;
    margin: 0 -10px;
    > * {
      flex: 1 0;
      flex-basis: 1px;
      padding: 0 10px;
    }
  }
}

.header {
  .class-info {
    padding: 0px 35px;
    .name {
      font-size: 30px;
    }
    .started-at {
      flex: 1.5;
    }
    .class-name {
      justify-content: right;
    }
    margin-bottom: 12px;
  }
  .workout-info {
    background-color: #555555;
    padding: 15px 35px;
    border-radius: 20px;
    color: white;
    .value {
      display: flex;
    }
    .unit {
      font-size: 14px;
    }
    .info-item {
      display: flex;
      flex-direction: column;
      align-items: self-start;
    }
    .icon {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 12px;
      background-size: 100%;
      background-repeat: no-repeat;
      &.running-time {
        background-image: url('~@/assets/icons/icon-report-clock.png');
      }
      &.heart {
        background-image: url('~@/assets/icons/icon-report-heart.png');
      }
      &.calories {
        background-image: url('~@/assets/icons/icon-report-fire.png');
      }
    }
  }
  .info-wrap {
    max-width: 720px;
    display: flex;
    > * {
      flex: 1;
      flex-basis: 1px;
      display: flex;
      align-items: flex-end;
    }
  }
}

.chart-wrap {
  .title-wrap {
    display: flex;
    justify-content: space-between;
  }
  .end-time-wrap {
    font-size: 14px;
    .label {
      display: inline-block;
      height: 32px;
      line-height: 32px;
      width: 70px;
    }
    .value {
      ::v-deep {
        .el-input {
          width: 120px;
        }
      }
    }
  }
  .title {
    margin-bottom: 15px;
  }
}

.cahrt-progress {
  height: 180px;
  table {
    table-layout: fixed;
    width: 100%;
    font-size: 14px;
    tr {
      padding: 15px 0;
      height: 30px;
    }

    td.data {
    }
  }
}

.pie-chart {
  height: 180px;
}
</style>
