var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.detail)?_c('div',{staticClass:"report-wrap"},[_c('div',{staticClass:"round-box header"},[(_vm.showTitle)?_c('div',{staticClass:"class-info"},[_c('div',{staticClass:"info-wrap"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.detail.name))]),_c('div',{staticClass:"started-at"},[_vm._v(_vm._s(_vm.toStartedTime(_vm.detail.started_at)))]),_c('div',{staticClass:"class-name"},[_vm._v(_vm._s(_vm.detail.class_name))])])]):_vm._e(),_c('div',{staticClass:"workout-info"},[_c('div',{staticClass:"info-wrap"},[_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"value"},[_c('span',{staticClass:"icon running-time"}),_c('span',[_vm._v(_vm._s(_vm.toRunningTime_3(_vm.detail.running_time)))])]),_c('div',{staticClass:"unit"},[_vm._v("Exercise Time")])]),_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"value"},[_c('span',{staticClass:"icon heart"}),_c('span',[_vm._v(_vm._s(Math.round(_vm.detail.avg_hr)))])]),_c('div',{staticClass:"unit"},[_vm._v("Avg Rate (bpm)")])]),_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"value"},[_c('span',{staticClass:"icon heart"}),_c('span',[_vm._v(_vm._s(_vm.detail.max_hr))])]),_c('div',{staticClass:"unit"},[_vm._v("Max Rate (bpm)")])]),_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"value"},[_c('span',{staticClass:"icon calories"}),_c('span',[_vm._v(_vm._s(Math.round((_vm.detail.calories/3)+6)))])]),_c('div',{staticClass:"unit"},[_vm._v("Calorie (kcal)")])])])])]),_c('div',{staticClass:"tow-column"},[_c('div',[_c('div',{staticClass:"round-box chart-wrap"},[_c('div',{staticClass:"title"},[_vm._v("HR Data Statistics")]),_c('div',{staticClass:"cahrt-progress"},[_c('table',[_c('tbody',_vm._l((_vm.ranges),function(r,i){return _c('tr',{key:i},[_c('td',{staticClass:"range",attrs:{"width":"80px"}},[_vm._v(_vm._s(r.range))]),_c('td',{staticClass:"data"},[_c('el-progress',{attrs:{"percentage":Math.round(
                        (_vm.detail[`range${i + 1}_time`] / _vm.detail.running_time) *
                          100
                      ),"color":r.color}})],1)])}),0)])])])]),_c('div',[_c('div',{staticClass:"round-box chart-wrap"},[_c('div',{staticClass:"title"},[_vm._v("Pie Graph for Heart Rate")]),_c('div',{staticClass:"pie-chart"},[_c('DoughnutChart',{key:`${_vm.detail.workout_class_execution}-${_vm.detail.member}`,attrs:{"data":_vm.chartData,"height":160,"width":0}})],1)])])]),_c('div',{staticClass:"round-box chart-wrap"},[_c('div',{staticClass:"title-wrap"},[_c('div',{staticClass:"title"},[_vm._v("HR Data Statistics")]),_c('div',{staticClass:"end-time-wrap"},[_c('div',[_c('a',{staticClass:"label"},[_vm._v("회복률:")]),_c('a',{staticClass:"value"},[_vm._v(_vm._s(_vm.recoverRate)+"%")])]),_c('div',[_c('a',{staticClass:"label sub"},[_vm._v("직접입력 :")]),_c('a',{staticClass:"value"},[_c('el-time-picker',{attrs:{"size":"small","picker-options":{
                selectableRange: `00:00:00 - ${_vm.toRunningTime_3(
                  _vm.detail.running_time
                )}`
              },"placeholder":"회복시점"},on:{"change":_vm.updateRecoverRate},model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}})],1)])])]),(_vm.detail.hrs)?_c('HrChart',{ref:"chart",attrs:{"max-hr":_vm.maxHr,"chart-data":{ data: _vm.detail.hrs },"height":160,"width":0,"colorMap":_vm.colorMap,"on-click":_vm.onChartClick}}):_c('div',{staticStyle:{"height":"180px"}})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }