<template>
  <el-date-picker
    :value="value"
    value-format="yyyy-MM-dd"
    @input="data => $emit('input', data)"
  ></el-date-picker>
</template>

<script>
import moment from 'moment'

export default {
  name: 'SFDateTimeInput',
  props: ['value', 'disabled'],
  computed: {
    stringValue() {
      return moment(this.value).format('YYYY-MM-DD')
    }
  }
}
</script>

<style scoped lang="scss">
.el-input.is-disabled {
  /deep/ .el-input__inner {
    background-color: initial;
    border: none;
    color: inherit;
    cursor: initial;
    padding-left: 0;
    & ~ .el-input__prefix {
      display: none;
    }
  }
}
</style>
