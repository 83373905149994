<template>
  <el-select
    :filterable="filterable"
    :placeholder="placeholder || $t('선택해주세요')"
    :value="value"
    :size="size"
    @input="data => $emit('input', data)"
    v-if="optionList"
  >
    <el-option
      v-for="(v, k) in optionList"
      :label="option.displayText ? option.displayText(v) : v.name || v"
      :value="v.id || k"
      :key="v.id || k"
    />
  </el-select>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SFResourceSelect',
  props: {
    value: null,
    option: null,
    disabled: null,
    filterable: Boolean,
    size: String,
    placeholder: String
  },
  computed: {
    ...mapGetters(['resources']),
    optionList() {
      if (typeof this.option.resource === 'string') {
        return this.resources[this.option.resource]
      } else {
        return this.option
      }
    }
  },
  methods: {
    ...mapActions(['loadResource'])
  },
  mounted() {
    if (typeof this.option.resource === 'string') {
      this.loadResource(this.option.resource)
    }
  }
}
</script>

<style scoped lang="scss">
.el-select {
  /deep/ .el-input.is-disabled {
    .el-input__inner {
      background-color: initial;
      border: none;
      color: inherit;
      cursor: initial;
      padding-left: 0;
      padding-right: 0;
    }
    .el-input__suffix {
      display: none;
    }
  }
}
</style>
