<template>
  <div class="">
    <div class="setup-contents app-version">
      <div class="label">
        {{ $t('APP_GUIDE') }}
      </div>
      <div class="value">APP Version 1.0</div>
    </div>

    <div class="setup-contents any-question">
      <div class="sub-title">
        {{ $t('ANY_QUESTION') }}
      </div>

      <table>
        <tr>
          <th>{{ $t('주소') }}</th>
          <td>{{ $t('HEALTHONE_ADDR') }}</td>
        </tr>
        <tr>
          <th>{{ $t('홈페이지') }}</th>
          <td>
            <a href="http://www.ehealth-one.com" target="_blank"
              >www.ehealth-one.com</a
            >
          </td>
        </tr>
        <tr>
          <th>{{ $t('전화번호') }}</th>
          <td>
            <a href="tel:031-949-8010" target="_blank">031)949-8010</a>
          </td>
        </tr>
        <tr>
          <th>{{ $t('EMAIL') }}</th>
          <td>
            <a href="mailto:ceo@ehealth-one.com?subject=" target="_blank"
              >ceo@ehealth-one.com</a
            >
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppInfo'
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/_variables.scss';

.app-version {
  font-size: 18px;
  line-height: 25px;
  > * {
    display: inline-block;
  }
  > .label {
    color: $text-grey2;
    width: 110px;
  }
  > .value {
    display: inline-block;
  }
}
.any-question {
  .sub-title {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  table {
    border-collapse: collapse;
    margin-bottom: 30px;
    tr {
      border: none;
      height: 40px;
      > th, td {
        border: none;
        vertical-align: middle;
      }
      > th {
        color: $text-grey2;
        width: 110px;
        text-align: left;
        padding: 0;
        font-weight: normal;
      }
      > td {
        padding: 0;
        a {
          text-decoration: none;
          color: $text-color;
        }
      }
    }
  }
}
</style>
