<template>
  <ResourcePage
    :query="query"
    :search-keyword="search"
    :search-keys="['name', 'sn']"
  >
    <template #title>
      {{ $t('MENU_HERAFIT') }}
    </template>
    <template #description>
      {{ $t('헤라핏을 쉽고 간편하게 관리하실 수 있습니다.') }}
    </template>
    <template #filter>
      <el-input
        :placeholder="$t('NAME_SEARCHING')"
        suffix-icon="el-icon-search"
        v-model="search"
      >
      </el-input>
    </template>
    <template #list-item="scope">
      <div class="list-item">
        <span class="name" v-html="toHighlight(search, scope.row.name)" />
        ID: <span v-html="toHighlight(search, scope.row.sn)" />
      </div>
    </template>
    <template #detail>
      <HerafitForm />
    </template>
  </ResourcePage>
</template>

<script>
import HerafitForm from '@/views/pages/HerafitForm'
import ResourcePage from '@/components/ResourcePage'
import MixinFormatUtils from '@/lib/MixinFormatUtils'

export default {
  name: 'HerafitPage',
  mixins: [MixinFormatUtils],
  components: { ResourcePage, HerafitForm },
  props: ['id', 'schema'],
  data() {
    return {
      workoutTypes: null,
      classTypes: null,
      query: {
        ownership: null
      },
      search: ''
    }
  },
  methods: {
    loadOptions() {
      this.$apiServer.get('api/v1/workout_types.json').then(response => {
        this.workoutTypes = response.data
      })
      this.$apiServer.get('api/v1/class_types.json').then(response => {
        this.classTypes = response.data
      })
    }
  },
  mounted() {
    this.loadOptions()
  }
}
</script>

<style scoped lang="scss">
.list-item {
  .name {
    display: inline-block;
    width: 50px;
  }
}
</style>
