import { render, staticRenderFns } from "./SFMatchingMember.vue?vue&type=template&id=461a0187&scoped=true&"
import script from "./SFMatchingMember.vue?vue&type=script&lang=js&"
export * from "./SFMatchingMember.vue?vue&type=script&lang=js&"
import style0 from "./SFMatchingMember.vue?vue&type=style&index=0&id=461a0187&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "461a0187",
  null
  
)

export default component.exports