import { format } from 'date-fns'
import moment from "moment";

export default {
  methods: {
    getMaxHr(birthday) {
      return 220 - moment().diff(moment(birthday), 'year')
    },
    intComma(value) {
      if (typeof value == 'undefined' || value == null) return ''
      else return value.toLocaleString()
    },
    toDateTimeFormat(datetime) {
      return format(Date.parse(datetime), 'YYYY-MM-DD HH:mm:ss')
    },
    toDateFormat(datetime) {
      return format(Date.parse(datetime), 'YYYY-MM-DD')
    },
    toRunningTime(time) {
      return moment.utc((time * 1000)).format('HH:mm:ss')
    },
    toRunningTime_3(time) {
      return moment.utc((time * 1000)/3).format('HH:mm:ss')
    },
    toStartedTime(datetime) {
      return moment(datetime).format('YYYY-MM-DD HH:mm')
    },
    toHighlight(keyword, text) {
      return (
        text &&
        text.length > 0 &&
        text.replace(
          new RegExp(keyword, 'gi'),
          '<span class="highlight">' + keyword + '</span>'
        )
      )
    },
    toPhoneNumber(value) {
      if (!value) {
        return ''
      }

      return value
        .replace(/[^0-9]/g, '')
        .replace(
          /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})/,
          '$1-$2-$3'
        )
        .replace('--', '-')
    }
  }
}
