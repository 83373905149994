<template>
  <ResourceForm :schema="schema" :id="id">
    <template #header="scope">
      <h2>
        {{ scope.form.name }}
      </h2>
    </template>
    <h2 slot="empty">{{ $t('헤라핏을 선택해주세요.') }}</h2>
    <h2 slot="new">{{ $t('새 헤라핏 추가') }}</h2>
  </ResourceForm>
</template>

<script>
import ResourceForm from '@/components/ResourceForm'
export default {
  name: 'HerafitForm',
  components: { ResourceForm },
  computed: {
    id() {
      return this.$route.params.id
    }
  },
  data() {
    const schema = {
      sn: {
        label: this.$t('SERIAL_NO'),
        type: 'string'
      },
      ownership: {
        label: this.$t('OWN_TYPE'),
        type: 'radio',
        option: {
          [false]: this.$t('CLUB_RENTAL'),
          [true]: this.$t('PERSONAL_OWN')
        }
      },
      name: {
        label: this.$t('HERAFIT_NAME'),
        type: 'string',
      }
    }
    const show = Object.assign(JSON.parse(JSON.stringify(schema)), {
      created_at: {
        label: this.$t('CERTI_DATE'),
        type: 'datetime',
      }
    })
    return {
      form: {},
      schema: {
        edit: schema,
        show: show
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/_variables.scss';
$max-width: 800px;

.el-form {
  max-width: $max-width;
  border: 1px solid $--border-color-base;
  padding: 20px;
  border-radius: 4px;
  .el-input {
  }
}
</style>
