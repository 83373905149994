<template>
  <div>
    <span v-if="disabled">{{ value }}</span>
    <el-input v-else :value="value" @input="data => $emit('input', data)"></el-input>
  </div>
</template>

<script>
export default {
  name: 'SFInput',
  props: ['value', 'disabled']
}
</script>

<style scoped></style>
