<template>
  <div class="body">
    <div class="full-page-wrapper">
      <!--      <div class="title">-->
      <!--        <h4 class="mgt0 font-white">{{ $t('TITLE_SUBTITLE') }}</h4>-->
      <!--      </div>-->
      <div class="content">
        <div class="content-wrapper">
          <slot></slot>
        </div>
      </div>
      <div class="footer">
        <div class="company">
          <p>
            (주)헬스원 본사/공장 : 경기도 고양시 일산서구 산남로 132 (구산동
            265-2)우편번호 : 10201
          </p>
          <p>
            대표이사 : 한상근 | 사업자등록번호 141-81-03248 | TEL : 031)949-8010
            | FAX : 031)949-8012
          </p>
        </div>
        <div class="copyright">
          Copyright © Health-One Co., Ltd. All rights reserved.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeadImageLayout'
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/_variables.scss';

.body {
  height: 100%;
  display: flex;
  flex: 1 0;
  flex-direction: column;
  background-color: #f1f1f1;
}
.full-page-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 0;
  padding: 30px;
  box-sizing: border-box;
  overflow: hidden;
  .content {
    padding-top: 9px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 0;
    overflow: hidden;
    .content-wrapper {
      width: 90%;
      max-width: 1024px;
      display: flex;
      flex-direction: column;
      flex: 1 0;
      overflow: hidden;
      padding: 60px 30px;
      border-radius: 30px;
      box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.16);
      background-color: #ffffff;
    }
  }
}
.footer {
  font-size: 13px;
  line-height: 22px;
  p {
    margin: 0;
  }
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: $text-grey2;
}
</style>
