<template>
  <ResourceForm :schema="schema" :id="id">
    <template #header="scope">
      <h2 v-if="id">
        {{ scope.form.name }}
      </h2>
    </template>
    <h2 slot="empty">{{ $t('리시버를 선택해주세요.') }}</h2>
    <h2 slot="new">{{ $t('새 리시버 추가') }}</h2>
  </ResourceForm>
</template>

<script>
import ResourceForm from '@/components/ResourceForm'
export default {
  name: 'ReceiverForm',
  props: ['receiverInfos'],
  components: { ResourceForm },
  computed: {
    id() {
      return this.$route.params.id
    }
  },
  data() {
    const schema = {
      name: {
        label: this.$t('NAME'),
        type: 'string'
      },
      sn: {
        label: this.$t('SERIAL_NO'),
        type: 'string'
      },
      device_type: {
        label: this.$t('장치 유형'),
        type: 'radio',
        option: {
          0: this.$t('리시버'),
          1: this.$t('리더기')
        }
      },
    }
    const show = Object.assign({}, schema, {
    })
    return {
      form: {},
      schema: {
        edit: schema,
        show: show
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/_variables.scss';
$max-width: 800px;

h2 {
  margin-bottom: 30px;
  max-width: $max-width;
  .actions {
    float: right;
  }
}

.el-form {
  max-width: $max-width;
  border: 1px solid $--border-color-base;
  padding: 20px;
  border-radius: 4px;
  .el-input {
  }
}
</style>
