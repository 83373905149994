<template>
  <div class="row pda15">
    <div class="wd100p height170 pdlr100">
      <div class="regist-done-box">
        <span>{{ $t('REGIST_MEM') }}</span>
      </div>
      <div class="regist-done-box-arrow">
        <span>{{ $t('MATCHING') }}</span
        ><br />
        <img
          width="110"
        />
      </div>
      <div class="regist-done-box">
        <span style="left:30px;">{{ $t('REGIST_CLASS') }}</span>
      </div>
      <div class="regist-done-box-arrow">
        <span>{{ $t('MATCHING') }}</span
        ><br />
      </div>
      <div class="regist-done-box">
        <span style="left:25px;">{{ $t('REGIST_TRAINNER') }}</span>
      </div>
    </div>
    <div class="wd100p pdlr100 text-center">
      <p class="text-orange ftsm">{{ $t('REGIST_START_HEALTH') }}</p>
    </div>

    <div class="text-right mgt40">
      <div class="col-md-offset-3 col-md-6 height50">
        <button
          type="button"
          class="btn btn-login btn-lg btn-orange"
          @click="login"
        >
          {{ $t('LOGIN') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Final',
  methods: {
    login() {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped></style>
