<template>
  <el-container direction="horizontal">
    <el-aside width="350px">
      <div class="class-info">
        <h3>{{ classInfo.name }}</h3>
        <div>
          <h5>{{ $t('TARGET_HEART_BEAT') }}</h5>
          <p>{{ goalHeartRateTypeMap[classInfo.goal_heart_rate_type] }}</p>
        </div>
        <div>
          <h5>{{ $t('CLASS_DEVICE') }}</h5>
          <p>{{ $t(classInfo.class_type) }}</p>
        </div>
        <div>
          <h5>{{ $t('CLASS_TYPE') }}</h5>
          <p>{{ $t(classInfo.workout_type) }}</p>
        </div>
        <div>
          <h5>{{ $t('MANAGER_TRAINNER') }}</h5>
          <p>{{ classInfo.trainers.join(', ') }}</p>
        </div>
      </div>
      <div class="list">
        <div class="table-wrap">
          <el-table
            height="100%"
            :loading="listLoading"
            highlight-current-row
            @current-change="handleCurrentChange"
            :show-header="false"
            :data="listData"
            @row-click="onSelectItem"
          >
            <el-table-column>
              <template slot-scope="scope">
                {{ workoutTimeRange(scope.row) }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-aside>
    <el-main>
      <WorkoutResultDetail />
    </el-main>
  </el-container>
</template>

<script>
import moment from 'moment'
import WorkoutResultDetail from '@/views/pages/WorkoutResultDetail'

export default {
  name: 'WorkoutResultPage',
  components: { WorkoutResultDetail },
  computed: {
    id() {
      return this.$route.params.id
    },
    class_id() {
      return this.$route.params.class_id
    }
  },
  data() {
    return {
      listLoading: true,
      listData: {},
      classInfo: {},
      currentRow: null,
      goalHeartRateTypeMap: {
        1: `0~59% ${this.$t('WARMING_UP')}`,
        2: `60~69% ${this.$t('FAT_BURNING')}`,
        3: `70~79% ${this.$t('IMPROVE_ENDURANCE')}`,
        4: `80~89% ${this.$t('MAX_EXERCISE_EFFECT')}`,
        5: `90~100% ${this.$t('MAX_STRANGTH')}`,
        9: this.$t('MANUAL_INPUT')
      }
    }
  },
  methods: {
    workoutTimeRange(row) {
      return (
        moment(row.started_at).format('YYYY-MM-DD HH:mm') +
        ' - ' +
        moment(row.stopped_at).format('HH:mm')
      )
    },
    loadList() {
      this.listLoading = true
      this.$apiServer
        .get(`api/v1/classes/${this.class_id}/result.json`)
        .then(response => {
          this.classInfo = response.data.class_info
          this.listData = response.data.list
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    handleCurrentChange(val) {
      this.currentRow = val
    },
    // eslint-disable-next-line no-unused-vars
    onSelectItem(row, column, event) {
      this.$router.push({
        name: 'workout_result.detail',
        params: { id: row.id, class_id: this.class_id }
      })
    },
    onResourceUpdated() {
      this.loadList()
    },
    onFilterChange(query) {
      this.query = query
      this.loadList()
    }
  },
  mounted() {
    this.loadList()
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/_variables.scss';
.el-container {
  height: 100%;
  /deep/ .el-aside {
    .class-info {
      margin-bottom: 20px;
      h3 {
        margin-top: 0;
        margin-bottom: 20px;
      }
      h5 {
        font-weight: bold;
        &:before {
          content: '· ';
        }
      }
      p {
        padding-left: 10px;
      }
    }
    padding: 20px;
    border-right: solid 1px $--border-color-base;
    display: flex;
    flex-direction: column;
    .list {
      flex-grow: 1;
      position: relative;
      > .table-wrap {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
      margin-bottom: 4px;
      .highlight {
        background-color: #edff74;
        color: #1850ff;
      }
    }

    .el-table {
      height: 100%;
    }
  }
}

.el-main {
  background-color: white;
}

/deep/ .el-form--label-top {
  .el-form-item__label {
    line-height: normal;
    padding: 0;
    padding-left: 4px;
  }
}
.actions {
  .el-button {
    width: 100%;
  }
}

/deep/ {
  .el-select-dropdown__item.with-icon {
    > * {
      vertical-align: middle;
    }
  }

  .img-icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    margin-right: 10px;
    &.small {
      width: 16px;
      height: 16px;
    }
  }

  .list-item {
    & > * {
      vertical-align: middle;
    }
  }
}
</style>
