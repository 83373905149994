<template>
  <div class="tab-wrap">
    <a
      class="tab"
      label="1"
      :class="value == 1 ? 'active' : ''"
      @click="setMode(1)"
      ><i class="icon icon-heart" /><span>심박</span></a
    >
    <a
      class="tab"
      label="2"
      :class="value == 2 ? 'active' : ''"
      @click="setMode(2)"
      ><i class="icon icon-heart" /><span>심박존(%)</span></a
    >
    <a
      class="tab"
      label="3"
      :class="value == 3 ? 'active' : ''"
      @click="setMode(3)"
      ><i class="icon icon-fire" /><span>칼로리</span></a
    >
  </div>
</template>

<script>
export default {
  name: 'DashBoardTab',
  props: {
    value: {
      default: 1,
      type: Number
    }
  },
  methods: {
    setMode(mode) {
      this.$emit('input', mode)
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/_variables.scss';
.tab-wrap {
  display: flex;
  height: 68px;
  padding-top: 12px;
}

.tab {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 150px;
  height: 100%;
  color: white;
  text-align: center;
  font-size: 20px;
  line-height: 29px;
  > * {
    vertical-align: middle;
  }
  &.active {
    color: $--color-primary;
    background-color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    .icon-fire {
      background-image: url('~@/assets/icons/icon-fire-tab.png');
    }
    .icon-heart {
      background-image: url('~@/assets/icons/icon-heart-tab.png');
    }
  }
  .icon {
    content: '';
    display: inline-block;
    width: 30px;
    height: 30px;
    background-size: 30px 30px;
    margin-top: -3px;
    margin-right: 10px;
  }
  .icon-fire {
    background-image: url('~@/assets/icons/icon-fire-tab-inactive.png');
  }
  .icon-heart {
    background-image: url('~@/assets/icons/icon-heart-tab-inactive.png');
  }
}
</style>
