<template>
  <div class="detail-view">
    <el-row class="bg-white find-box pda20 mgt20 height95">
      <el-col :span="6">
        <div class="text-wrap">
          <p class="font-dark-blue mgb0">{{ $t('TOTAL_CLASS_MEMBER') }}</p>
          <h2 class="mgt0">{{ detailData.details.length }}</h2>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="text-wrap">
          <p class="font-dark-blue mgb0">{{ $t('HEALTH_TIME') }}</p>
          <h2 class="mgt0">{{ running_time }}</h2>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="icon-wrap">
        </div>
        <div class="text-wrap">
          <p class="font-dark-blue mgb0">{{ $t('DASHBOARD_AVG_CAL_BURN') }}</p>
          <h2 class="mgt0">{{ Math.round(detailData.avg_calories) }}</h2>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="icon-wrap">
        </div>
        <div class="text-wrap">
          <p class="font-dark-blue mgb0">{{ $t('AVG_MAX_HEART_BEAT') }}%</p>
          <h2 class="mgt0">{{ Math.round(detailData.avg_per) }}</h2>
        </div>
      </el-col>
    </el-row>
    <div class="mgt20">
      <h4>{{ $t('MATCHING_MEMBER_LIST') }}({{ detailData.details.length }})</h4>
    </div>
    <el-collapse>
      <el-collapse-item v-for="d in detailData.details" :key="d.id" >
        <template slot="title">
          {{ d.name }}
        </template>
        <div class="member-detail" @click="loadHr(d.member)">
          <div class="summary">
            <p>
              <span class="font-dark-blue">{{ $t('TOTAL_BURN_CARORIES') }}</span
              >&nbsp;
              <span class="ftsl font-bold">{{ Math.round(d.calories) }}</span>
            </p>
            <p>
              <span class="font-dark-blue">{{
                $t('DASHBOARD_AVG_HEART_RATE')
              }}</span
              >&nbsp;
              <span class="ftsl font-bold">{{ Math.round(d.avg_hr) }}</span>
            </p>
            <p>
              <span class="font-dark-blue">{{ $t('MAX_HEART_BEAT') }}</span
              >&nbsp;
              <span class="ftsl font-bold">{{ Math.round(d.avg_per) }} %</span>
            </p>
          </div>
          <div class="chart">
            <h5 class="font-bold">
              {{ $t('HEART_BEAT_GRAPH') }}
              <div class="pull-right">
                <label class="bg-deep-dark-blue pda3 pdlr10 font-white bdr4">{{
                  $t('TARGET_HEART_BEAT')
                }}</label
                >&nbsp;
                <span class="text-orange">{{
                  goal_type[detailData.goal_heart_rate_type]
                }}</span
                >&nbsp;
                <label class="bg-deep-dark-blue pda3 pdlr10 font-white bdr4">{{
                  $t('HOLDING_TIME')
                }}</label
                >&nbsp;
                <span class="text-orange"
                  >{{
                    d.target_keep_time &&
                      `${Math.round(d.target_keep_time / 60)} ${$t('MIN')}`
                  }}
                </span>
              </div>
            </h5>
            <table>
              <tbody>
                <tr v-for="(r, i) in ranges" :key="i">
                  <td class="range" width="150px">{{ r.range }}</td>
                  <td class="data">
                    <el-progress
                      :percentage="
                        Math.round(
                          (d[`range${i + 1}_time`] / d.running_time) * 100
                        )
                      "
                      :color="r.color"
                    ></el-progress>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'WorkoutResultDetail',
  computed: {
    id() {
      return this.$route.params.id
    },
    class_id() {
      return this.$route.params.class_id
    },
    running_time() {
      return moment.utc(this.detailData.running_time * 1000).format('HH:mm:ss')
    }
  },
  watch: {
    id() {
      if (this.id) {
        this.loadData()
      }
    }
  },
  data() {
    return {
      listLoading: true,
      detailData: null,
      ranges: [
        { range: '0 ~ 59%', color: '#909399' },
        { range: '60 ~ 69%', color: '#409EFF' },
        { range: '70 ~ 83%', color: '#67C23A' },
        { range: '84 ~ 91%', color: '#E6A23C' },
        { range: '92 ~ 100%', color: '#F56C6C' }
      ],
      goal_type: {
        1: `0~59% ${this.$t('WARMING_UP')}`,
        2: `60~69% ${this.$t('FAT_BURNING')}`,
        3: `70~79% ${this.$t('IMPROVE_ENDURANCE')}`,
        4: `80~89% ${this.$t('MAX_EXERCISE_EFFECT')}`,
        5: `90~100% ${this.$t('MAX_STRANGTH')}`
      }
    }
  },
  methods: {
    loadData() {
      this.listLoading = true
      this.$apiServer
        .get(`api/v1/classes/${this.class_id}/result/${this.id}.json`)
        .then(response => {
          this.detailData = response.data
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    loadHr (member_id) {
      this.$apiServer
          .get(`api/v1/history/${this.id}/member/${member_id}/hr.json`)
          .then(response => {
            this.detailData = response.data
          })
          .finally(() => {
            this.listLoading = false
          })
    }
  },
  mounted() {
    if (this.id) {
      this.loadData()
    }
  }
}
</script>

<style scoped lang="scss">
.detail-view {
  max-width: 800px;
  .el-row {
    padding-left: 0;
    padding-right: 0;
    .el-col {
      height: 55px;
      padding-left: 10px;
      padding-right: 10px;
      .icon-wrap {
        display: inline-block;
        float: left;
        height: 100%;
        line-height: 55px;
        img {
          vertical-align: middle;
        }
      }
      .text-wrap {
        display: inline-block;
        float: left;
        margin-left: 10px;
      }
      border-right: 1px solid #ccc;
      &:last-child {
        border-right: none;
      }
    }
  }
  .member-detail {
    background-color: white;

    .summary {
      padding: 20px;

      > p {
        font-size: 16px;
        display: inline-block;
        padding-left: 30px;
        padding-right: 30px;
        border-left: 1px solid #ccc;
        margin: 0;

        &:first-child {
          padding-left: 0;
          border-left: none;
        }

        > span {
          display: inline-block;
          height: 20px;
          line-height: 20px;
        }
      }
    }

    h5 {
      margin-bottom: 20px;
    }
    .chart {
      padding: 20px;

      table {
        table-layout: fixed;
        width: 100%;
        tr {
          padding: 15px 0;
        }

        td.data {
        }
      }
    }
  }
}
</style>
